import * as React from "react";

import {
  makeStyles,
  Body1,
  Caption1,
  Button,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  Card,
  CardFooter,
  CardHeader,
} from "@fluentui/react-components";
import { 
    Save24Regular,
} from "@fluentui/react-icons";
import {
    Switch,
    SwitchProps,
} from "@fluentui/react-components";
import { useMSCSPContext as useContext} from "../../Context/MSCSPContext";
import { useClientprincipal as useServiceContext} from "../../Context/ClientprincipalContext";
import type { IServiceManagementItem } from "../../Context/ClientprincipalContext";
const servicename = 'mscsp'


const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const ServiceCard = () => {
  const {serviceOffer, subscription} = useContext();
  const {mergeServiceEnabled} = useServiceContext();
  const styles = useStyles();
  const [subscriptionSettings, setSubscriptionSettings] = React.useState<IServiceManagementItem | undefined>(subscription);
  
  const onClickSaveButton = React.useCallback(() => {
    (subscriptionSettings) && (
      mergeServiceEnabled(
        {
          name: servicename,
          isEnabled: subscriptionSettings.isEnabled
        }
      )
    )
  }, [subscriptionSettings, mergeServiceEnabled]);

  const SaveButton = React.useCallback(() => {
    return (
        (subscription && subscriptionSettings && subscriptionSettings.isEnabled !== subscription.isEnabled) ? (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton}>Save</Button>
        ) : (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" disabled>Save</Button>
        )

    )
  },[subscriptionSettings, subscription, onClickSaveButton])

  const SubscriptionEnabled = React.useCallback((props: SwitchProps) => (
    (subscription && subscriptionSettings) ? (
      <Switch 
        checked={subscriptionSettings.isEnabled}
        onChange={() => setSubscriptionSettings({
          ...subscriptionSettings,
          isEnabled: !(subscriptionSettings.isEnabled)
        })}
        {...props}
      />
    ) : (
      <Switch 
        checked={subscriptionSettings?.isEnabled}
        disabled
      />
    )
  ),[subscriptionSettings, subscription]);

  return (
      <Card className={styles.card}>
        <CardHeader
          image = {
              <SubscriptionEnabled />
          }
          header={
              <Body1>
                  <b>{serviceOffer?.displayname}</b>
              </Body1>
          }
          description={
              <Caption1>
                {
                (subscriptionSettings) ? (
                  (subscriptionSettings.isEnabled === subscription?.isEnabled) ? (
                    (subscription?.isEnabled) ? (
                      `Service is enabled`
                    ) : (
                      `Service is disabled`
                    )
                  ) : (
                    (subscriptionSettings?.isEnabled) ? (
                      `Click save to enable the service`
                    ) : (
                      `Click save to disable the service.`
                    )
                  )
                ) : (
                  `No subscription found, service is unavailable.`
                )
              }
              </Caption1>
          }
        />
        <div className={styles.description}>
          {serviceOffer?.description}
        </div>
        <CardFooter>
          <SaveButton />
        </CardFooter>
      </Card>
  );
};
