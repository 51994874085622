import * as React from "react";
import { default as TabsWithPanels } from './TabsWithPanels'
import { AztaProvider } from '../../Context/AztaContext'

export const WithPanels = () => {
  return (
    <AztaProvider>
        <TabsWithPanels />
    </AztaProvider>
  );
};

export default WithPanels;
