import * as React from "react";
import {
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
  Button,
} from "@fluentui/react-components";
import { Link } from "@fluentui/react-components";
import type { LinkProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const ApprovAccess = (props: LinkProps & { as?: "a" }) => (
  <Link href="https://login.microsoftonline.com/common/adminconsent?client_id=7e010330-5f19-4a82-8e78-7e4747730ab1" as="a" target="_blank">
    <Button shape="circular" appearance="outline">Approve Access</Button>
  </Link>
);

const CopyLink = () => (
  <Button shape="circular" appearance="outline" onClick={() => {navigator.clipboard.writeText("https://login.microsoftonline.com/common/adminconsent?client_id=7e010330-5f19-4a82-8e78-7e4747730ab1")}}>
    Copy Link
  </Button>
)

export const Faq = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
        <LargeTitle>Instructions</LargeTitle>
        <Body1 align="justify">Ths guide describes the configuration steps needed to start using the Asset Management service.</Body1>
        <Title3>1. Approve the Modern Managed Asset Management Service application.</Title3>
        <Body1 align="justify">Allow the Asset Management Service application access to your AzureAD tenant by approving the enterprise application.</Body1>
        <Body1 align="start">
          <ApprovAccess /> <CopyLink />
        </Body1>
        <Title3>2. Assets</Title3>
        <Body1 align="justify">Wait for the service to update the Asset items from Intune.</Body1>
        <Title3>3. Work with asset locations & notes</Title3>
        <Subtitle1>3.1 Create locations that can be assigned to devices.</Subtitle1>
        <Body1 align="justify">
          <li>Create locations that can be assigned to your assets.</li>
        </Body1>
        <Subtitle1>3.2 Assign locations & notes to assets.</Subtitle1>
        <Body1 align="justify">
          <li>Start assigning a location to inactive devices.</li>
          <li>Use device notes to assign additional infomration to devices.</li>
        </Body1>
      </div>
    )
};

export default Faq;