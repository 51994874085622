import * as React from "react";
import {
  ArrowSync24Regular,
  Dismiss24Regular,
  TextColumnThree24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
  SearchBox,
} from "@fluentui/react-components";
import type { SearchBoxProps } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import { Button, tokens } from "@fluentui/react-components";
import { Checkbox } from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import {
  useMSCSPContext as useContext,
  ILogItem as IGridItem,
} from '../../Context/MSCSPContext'
import {OpenLog as OpenItem} from './Drawer'
import {SortString, SortNumber, SortDate} from "../../Functions/sorting";

const defaultColumns = ['actions','logDateTime','offerName','orderQuantity','quantity','groupDisplayName','groupMemberCount','offset'];
const searchPropertyName = 'offerName'
const sortAscending = false
const sortColumn = "logDateTime"

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
          <OpenItem {...item} />
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "offerName",
    compare: (a, b) => {
      return SortString(a.offerName,b.offerName)
    },
    renderHeaderCell: () => {
      return "Offer Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.offerName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "subscriptionId",
    compare: (a, b) => {
      return SortString(a.subscriptionId,b.subscriptionId)
    },
    renderHeaderCell: () => {
      return "Subscription Id";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.subscriptionId}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "groupDisplayName",
    compare: (a, b) => {
      return SortString(a.groupDisplayName,b.groupDisplayName)
    },
    renderHeaderCell: () => {
      return "Group Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.groupDisplayName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "groupId",
    compare: (a, b) => {
      return SortString(a.groupId,b.groupId)
    },
    renderHeaderCell: () => {
      return "Group Id";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.groupId}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "offset",
    compare: (a, b) => {
      return SortNumber(a.offset,b.offset)
      //return a.offset - b.offset
    },
    renderHeaderCell: () => {
      return "Offset";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.offset.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "quantity",
    compare: (a, b) => {
      return SortNumber(a.quantity,b.quantity)
      //return a.quantity - b.quantity
    },
    renderHeaderCell: () => {
      return "Quantity";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.quantity.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "orderQuantity",
    compare: (a, b) => {
      return SortNumber(a.orderQuantity,b.orderQuantity)
      //return a.orderQuantity - b.orderQuantity
    },
    renderHeaderCell: () => {
      return "Order Quantity";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.orderQuantity.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "previousQuantity",
    compare: (a, b) => {
      return SortNumber(a.previousQuantity,b.previousQuantity)
      //return a.previousQuantity - b.previousQuantity
    },
    renderHeaderCell: () => {
      return "Previous Quantity";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.previousQuantity.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "refundableTotalQuantity",
    compare: (a, b) => {
      return SortNumber(a.refundableTotalQuantity,b.refundableTotalQuantity)
      //return a.refundableTotalQuantity - b.refundableTotalQuantity
    },
    renderHeaderCell: () => {
      return "Refundable Quantity";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.refundableTotalQuantity.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "groupMemberCount",
    compare: (a, b) => {
      return SortNumber(a.groupMemberCount,b.groupMemberCount)
      //return a.groupMemberCount - b.groupMemberCount
    },
    renderHeaderCell: () => {
      return "Members";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.groupMemberCount.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "nextTermQuantity",
    compare: (a, b) => {
      return SortNumber(a.nextTermQuantity,b.nextTermQuantity)
      //return a.nextTermQuantity - b.nextTermQuantity
    },
    renderHeaderCell: () => {
      return "Next Term Quantity";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.nextTermQuantity.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "logDateTime",
    compare: (a, b) => {
      return SortDate(a.logDateTime,b.logDateTime)
      //return Date.parse(a.logDateTime).valueOf() - Date.parse(b.logDateTime).valueOf()
    },
    renderHeaderCell: () => {
      return "Timestamp";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.logDateTime }
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  offerName: {
    minWidth: 200,
    defaultWidth: 300,
    idealWidth: 300,
  },
  logDateTime: {
    minWidth: 200,
    defaultWidth: 300,
    idealWidth: 300,
  },
  groupDisplayName: {
    minWidth: 200,
    defaultWidth: 300,
    idealWidth: 300,
  },
  subscriptionId: {
      minWidth: 100,
      defaultWidth: 200,
  },
  groupId: {
    minWidth: 100,
    defaultWidth: 200,
  },
  quantity: {
    minWidth: 60,
    defaultWidth: 60,
  },
  nextTermQuantity: {
    minWidth: 60,
    defaultWidth: 60,
  },
  offset: {
    minWidth: 60,
    defaultWidth: 60,
  },
  orderQuantity: {
    minWidth: 60,
    defaultWidth: 60,
  },
  previousQuantity: {
    minWidth: 60,
    defaultWidth: 60,
  },
  refundableTotalQuantity: {
    minWidth: 60,
    defaultWidth: 60,
  },
  groupMemberCount: {
    minWidth: 60,
    defaultWidth: 60,
  },
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});


export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const {logs, isLoadingLogs, refreshLogs} = useContext();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns
      .includes(o.columnId.toString())
    )))
  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        {
          isLoadingLogs ? (
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular className="Spinner-icon" />}
              size="medium"
            >Refresh</Button>
          ) : (
              <Button
                shape="square"
                appearance="subtle"
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={refreshLogs}
                size="medium"
              >Refresh</Button>
          )
        }
      </div>
    )
  }

  // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter (offerName)
  React.useEffect(() => {
    if (Array.isArray(logs)) {
      if(searchFilter !== "") {
        setFilteredItems(
          logs.filter(item => 
            (
              (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
            )
          )
        )
      } else {
        setFilteredItems(logs)
      }
    }
  },[searchFilter, logs])
  
  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IGridItem>;
    }
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    const handleCheckBox = React.useCallback((index:number) => {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    },[columnsRef])
    
    const onClickApplyButton = React.useCallback(() => {
      setIsOpen(false);
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
    }, [columnsRef]);

    const onClickColumnsButton = React.useCallback(() => {
      setIsOpen(true);
    }, []);

    return (
      <div>
        <OverlayDrawer
          position="end"
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <Checkbox
              checked={
                  columnsRef.every( o => o.enabled === true ) ? true : columnsRef.every( o => o.enabled === false ) ? false : "mixed"
              }
              onChange={(_ev, data) => {
                const nextColumnsRef = columnsRef
                    .map((o) => {
                      return {
                        name: o.name,
                        enabled: !!data.checked,
                        column: o.column
                      }
                    });

                setColumnsRef(
                  nextColumnsRef
                )
              }}
              label="All columns"
            />
            <br></br>
            {
              columnsRef.map((o, index) =>
                <div>
                <Checkbox
                  key={index}
                  checked={o.enabled}
                  onChange={() => handleCheckBox(index)}
                  label={o.name}
                /><br></br>
                </div>
              )
            }
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            </div>
          </DrawerBody>
        </OverlayDrawer>
        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickColumnsButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };

  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: sortColumn, sortDirection: (sortAscending) ? ("ascending") : ("descending")}), []
  );

  // GridArea (isLoadingLogs)
  const GridArea = React.useCallback(() => {
    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.Timestamp}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          {
            (!isLoadingLogs) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <div className='App-tablearea'>
                <Spinner label="Loading..." labelPosition="after"/>
              </div>
            )
          }
        </DataGrid>
      </div>
    )
  },[filteredItems,showColumns,isLoadingLogs,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
      <Field
        validationState={validSearchFilter ? "success" : "warning"}
        validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+logs.length+")" : "Input is limited to 20 characters."}
      >
        <tr>
          <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
          <td><RefreshButton /></td>
          <td><ColumnsDrawer /></td>
        </tr>
      </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;