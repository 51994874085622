import React from "react"

export interface IServiceManagementItem {
    id: string;
    description: string;
    displayname: string;
    name: string;
    isActive: boolean;
    isEnabled: boolean;
    currencycode: string;
    retailprice: string;
    unittype: string;
    expire: string;
    autorenew: boolean;
}

export interface ISubscriptionEnabledItem {
    isEnabled: boolean;
    name: string;
}

export interface IServiceStatusItem {
    id: string;
    name: string;
    isActive: boolean;
    autorenew: boolean;
}

export interface IServiceOffer {
    id: string;
    displayname: string;
    name: string;
    description: string;
    currencycode: string;
    retailprice: string;
    unittype: string;
}

export interface IClientprincipal {
    isAuthenticated: boolean;
    response: any | null;
    tenantId?: string | null;
    userId?: string | null;
    roles?: string[] | null;
    userName?: string | null;
    displayName?: string | null;
    claims?: IClientprincipalClaim[] | null;
}

export interface IClientprincipalClaim {
    typ: string;
    val: string;
}

export type ClientprincipalContextType = {
    clientprincipal: IClientprincipal;
    serviceOffers: IServiceOffer[];
    serviceSubscriptions: IServiceManagementItem[];
    serviceSubscriptionsIsLoading: boolean;
    userPhoto: string | null;
    refreshClientprincipal: () => void;
    DisposeClientprincipal: () => void;
    refreshServiceSubscriptions: () => void;
    mergeServiceEnabled: (props: ISubscriptionEnabledItem) => void;
    mergeServiceSubscriptionStatus: (props: IServiceStatusItem) => void;
};

export type IClientprincipalObject = {
    "identityProvider": string,
    "userId": string,
    "userDetails": string,
    "userRoles": string[],
    "claims": IClientprincipalClaim[],
}

export interface IProviderProps {
    children?: any;
}


const initialClientprincipalState = {
    isAuthenticated:    false,
    response:           null,
}

const ClientprincipalDefaultValue = {
    clientprincipal: initialClientprincipalState,
    serviceOffers: [],
    serviceSubscriptions: [],
    serviceSubscriptionsIsLoading: false,
    userPhoto: null,
    refreshClientprincipal: () => {},
    DisposeClientprincipal: () => {},
    refreshServiceSubscriptions: () => {},
    mergeServiceEnabled: () => {},
    mergeServiceSubscriptionStatus: () => {},
};

const ClientprincipalContext = React.createContext<ClientprincipalContextType>(ClientprincipalDefaultValue)

export const useClientprincipal = () => {
  const context = React.useContext(ClientprincipalContext);
  if (!context) {
    throw new Error(
        `useClientprincipal must be used within a ClientprincipalProvider`
    )
  }
  return context;
}

export const ClientprincipalProvider = (props: IProviderProps) => {
    const [clientprincipal, setClientprincipal] = React.useState<IClientprincipal>(initialClientprincipalState);
    const [serviceOffers, setServiceOffers] = React.useState<IServiceOffer[]>([]);
    const [serviceSubscriptions, setServiceSubscriptions] = React.useState<IServiceManagementItem[]>([]);
    const [serviceSubscriptionsIsLoading, setServiceSubscriptionsIsLoading] = React.useState<boolean>(false)
    const [userPhoto, setUserPhoto] = React.useState<string | null>(null);
    //const refreshClientprincipal = React.useCallback(() => {
    const refreshClientprincipal  = React.useCallback(() => {

        // Get Clientprincipal
        fetch('/.auth/me')
        .then((response) => response.json())
        .then(({ clientPrincipal }) => {
            if (!clientPrincipal) {
                setClientprincipal(initialClientprincipalState)
            } else {
                if (clientPrincipal !== clientprincipal.response) {
                    //alert ( "set clientprincipal")
                    setClientprincipal(
                        {
                            isAuthenticated:    true,
                            response:           clientPrincipal,
                            userName:           clientPrincipal['userDetails'],
                            displayName:       (clientPrincipal['claims'].find((element: IClientprincipalClaim) => {
                                                    return element.typ === "name";
                                                })).val,
                            userId:             clientPrincipal['userId'],
                            tenantId:           (clientPrincipal['claims'].find((element: IClientprincipalClaim) => {
                                                    return element.typ === "http://schemas.microsoft.com/identity/claims/tenantid";
                                                })).val,
                            roles:              clientPrincipal['userRoles'],
                            claims:             clientPrincipal['claims'],
                        }
                    )
                }
            }
               
        })
        .catch((error) => {
            console.log("An error occured")
            setClientprincipal(initialClientprincipalState)
        })
        .finally(() => {
            // x           
        })
    },[clientprincipal])

    const refreshServiceOffers = React.useCallback(() => {
      if(clientprincipal?.isAuthenticated 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
          
          // Get services 
          const requestOptions = {
            method: 'GET',
            headers: {  'Content-Type': 'application/json',
                        'tenantId': clientprincipal.tenantId,
                        'userprincipalname': clientprincipal.userName,
                        'uid': clientprincipal.userId,
                        'displayname': clientprincipal.displayName,
                      },
          };
          console.log(requestOptions)
          fetch('/api/services/offers', requestOptions)
          .then((response) => response.json())
          .then((result) => {setServiceOffers(result);console.log(result)})
          .catch((error) => console.log("An error occured"))
          .finally(() => {
          })
        }
    }, [clientprincipal?.isAuthenticated, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

    const refreshUserPhoto = React.useCallback(() => {
        if(clientprincipal?.isAuthenticated 
          && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
          && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
          
            // Get services 
            const requestOptions = {
              method: 'GET',
              headers: {  'Content-Type': 'application/json',
                          'tenantId': clientprincipal.tenantId,
                          'userprincipalname': clientprincipal.userName,
                          'uid': clientprincipal.userId,
                          'displayname': clientprincipal.displayName,
                        },
            };
            console.log(requestOptions)
            fetch('/api/user/photo', requestOptions)
            .then((response) => response.json())
            .then((result) => {setUserPhoto(result.photo)})
            .catch((error) => console.log("An error occured"))
            .finally(() => {
            })
        }
    }, [clientprincipal?.isAuthenticated, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName])

    const refreshServiceSubscriptions = React.useCallback(() => {
        if(clientprincipal?.isAuthenticated
          && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
          && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){

            // Get the tantas Subscriptions from Services 
          setServiceSubscriptionsIsLoading(true)
          const requestOptions = {
            method: 'GET',
            headers: {  'Content-Type': 'application/json',
                        'tenantId': clientprincipal.tenantId,
                        'userprincipalname': clientprincipal.userName,
                        'uid': clientprincipal.userId,
                        'displayname': clientprincipal.displayName,
                      },
          };
          fetch('/api/services', requestOptions)
          .then((response) => response.json())
          .then((result) => {setServiceSubscriptions(result);console.log(result) })
          .catch((error) => console.log("An error occured"))
          .finally(() => {
            setServiceSubscriptionsIsLoading(false)
          })
        } else {
          setServiceSubscriptions([])
        }
    }, [clientprincipal?.isAuthenticated, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

    const mergeServiceSubscriptionStatus = React.useCallback((props: Partial<IServiceStatusItem>) => {
        // Require isAuthenticated, tenantId, userName, userId and the serviceadministrator role
        // to PATCH the Service Subscriptions isActive and autorenew properties.
        if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') 
            && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
            && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
          
          // Update Service
          console.log('Updating service status')
          const requestOptions = {
            method: 'MERGE',
            headers: {  'Content-Type': 'application/json', 
                        'tenantId': clientprincipal.tenantId,
                        'userprincipalname': clientprincipal.userName,
                        'uid': clientprincipal.userId,
                        'displayname': clientprincipal.displayName,
                      },
            body: JSON.stringify({
              id: props['id'],
              tenantid: clientprincipal.tenantId,
              isActive: props['isActive'],
              autorenew: props['autorenew'],
              name: props['name']
            }),
          };
          fetch('/api/services', requestOptions)
          .then((response) => (response.status === 204) ? (
            refreshServiceSubscriptions()
          ) : (
            console.log('Failed to set service status.') 
          ))
          .catch((error) => console.log("An error occured" + error))
          .finally(() => {
          })
        }
    },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName, refreshServiceSubscriptions]);
    
    const mergeServiceEnabled = React.useCallback((props: ISubscriptionEnabledItem) => {
        // Require isAuthenticated, tenantId, userName, userId and the service role
        // to PATCH the Service isEnabled property.
        if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes(props['name']) 
            && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
            && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

          // Update Service
          console.log('Updating service status')
          const requestOptions = {
            method: 'MERGE',
            headers: {'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                      },
            body: JSON.stringify({
              id: clientprincipal.tenantId,
              isEnabled: props.isEnabled,
            }),
          };
          fetch(`/api/${props['name']}/service`, requestOptions)
          .then((response) => (response.status === 204) ? (
            refreshServiceSubscriptions()
          ) : (
            console.log('Failed to set service status.') 
          ))
          .catch((error) => console.log("An error occured" + error))
          .finally(() => {
          })
        }
    },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName, refreshServiceSubscriptions]);

    
    // Dispose clientprincipal (signout)
    const DisposeClientprincipal = React.useCallback(() => {
        setClientprincipal(initialClientprincipalState)
    }, []);

    // Get clientprincipal
    React.useEffect(() => {
        refreshClientprincipal();
        // eslint-disable-next-line
    },[]);

    // Get serviceOffers
    React.useEffect(() => {
        refreshServiceOffers();
    },[refreshServiceOffers,clientprincipal]);

    // Get serviceSubscriptions
    React.useEffect(() => {
        refreshServiceSubscriptions();
    },[refreshServiceSubscriptions,clientprincipal]);

    // Get userPhoto
    React.useEffect(() => {
        refreshUserPhoto();
    },[refreshUserPhoto,clientprincipal]);

    const value = React.useMemo(() => ({clientprincipal, serviceSubscriptions, serviceOffers, serviceSubscriptionsIsLoading, userPhoto, mergeServiceSubscriptionStatus, mergeServiceEnabled, refreshClientprincipal, DisposeClientprincipal, refreshServiceSubscriptions}), [clientprincipal, serviceSubscriptions, serviceSubscriptionsIsLoading, serviceOffers, userPhoto, mergeServiceSubscriptionStatus, mergeServiceEnabled, refreshClientprincipal, DisposeClientprincipal, refreshServiceSubscriptions])
    return <ClientprincipalContext.Provider value={value} {...props} />;

}
