import React from "react"
import {useClientprincipal} from './ClientprincipalContext'
import type { IServiceOffer, IServiceManagementItem } from './ClientprincipalContext'

export interface IRadiusLogItem {
    "id": string;
    "AuthServer": string;
    "CalledStationSSID": string;
    "DeviceComplianceState": string;
    "DeviceId": string;
    "DeviceName": string;
    "serialNumber": string;
    "operatingSystem": string;
    "DeviceOperatingSystem": string;
    "DevicePresent": boolean,
    "DeviceSerialNumber": string;
    "LogDateTime": string;
    "Reason": string;
    "Response": string;
    "TenantId": string;
    "Timestamp": string;
    "TlsClientCertCommonName": string;
    "TlsClientCertIssuer": string;
    "TlsClientCertOid14": string;
    "UserName": string;
    "ListenTlsClientCertCommonName": string;
}

export type RadiusContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: IServiceManagementItem | undefined;
  items: IRadiusLogItem[];
  isLoading: boolean;
  caCertificateDownload: () => void;
  clientCertificateDownload: () => void;
  refreshItems: () => void;
};

export interface IProviderProps {
  children?: any;
}

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

const RadiusDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  items: [],
  isLoading: false,
  caCertificateDownload: () => {},
  clientCertificateDownload: () => {},
  refreshItems: () => {},
};

const RadiusContext = React.createContext<RadiusContextType>(RadiusDefaultValue)

export const useRadiusContext = () => {
  const context = React.useContext(RadiusContext);
  if (!context) {
    throw new Error(
      `useRadiusContext must be used within a RadiusProvider`
    )
  }
  return context;
}

export const base64toblob = (props: {Base64String: string; fileName: string; type: string;}) => {
  console.log('Base64String: '+props.Base64String)
  console.log('fileName: '+props.fileName)
  console.log('type: '+props.type)
  const ByteArray = Uint8Array.from(atob(props.Base64String), c => c.charCodeAt(0))
  
  const blob = new Blob([ByteArray], {
      type: props.type //'application/x-x509-ca-cert' or 'application/x-pkcs12'
    });

  const blobUri = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobUri;
  a.download = props.fileName;
  a.click();
  a.remove();
}

export const RadiusProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [items, setItems] = React.useState<IRadiusLogItem[]>([]);
  const [clientCertificateAsBase64String, setClientCertificateAsBase64String] = React.useState<string>('');
  const [caCertificateAsBase64String, setCaCertificateAsBase64String] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false)
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);

  const refreshItems = React.useCallback(() => {
    if( caCertificateAsBase64String && clientCertificateAsBase64String 
          && subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') 
          && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
          && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
            
      // Get Items
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/radius/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => setItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
      
    } else {
      setItems([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName,caCertificateAsBase64String, clientCertificateAsBase64String]);

  const refreshClientCertificate = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
          
      setIsLoading(true);
      const uri = '/api/radius/certificates'
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
      };

      fetch(uri, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        console.log(payload)
        setClientCertificateAsBase64String(payload.ClientCertificate);
        setCaCertificateAsBase64String(payload.CaCertificate);
      })
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoading(false);
      })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const clientCertificateDownload = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
        
        base64toblob({Base64String:clientCertificateAsBase64String,fileName:'RadiusClientCertificate.pfx',type:'application/x-pkcs12'})
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName,clientCertificateAsBase64String]);

  const caCertificateDownload = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
          
        base64toblob({Base64String:caCertificateAsBase64String,fileName:'RadiusCaCertificate.cer',type:'application/x-x509-ca-cert'})
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName,caCertificateAsBase64String]);

  // Get log items
  React.useEffect(() => {
    refreshItems();
  }, [caCertificateAsBase64String, clientCertificateAsBase64String, clientprincipal, refreshItems]);

  // Get client and ca certificate
  React.useEffect(() => {
    refreshClientCertificate();
  }, [clientprincipal, refreshClientCertificate]);

  // Set serviceOffer
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "radius";
      }))
  },[serviceOffers, setServiceOffer]);

  // Set subscription
  React.useEffect(() => {
    setSubscription(
      serviceSubscriptions.find((element: IServiceManagementItem) => {
        return element.name === "radius";
      })
    )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({serviceOffer, subscription, items, isLoading, clientCertificateDownload, caCertificateDownload, refreshItems}), [serviceOffer, subscription, items, isLoading, clientCertificateDownload, caCertificateDownload, refreshItems])
  return <RadiusContext.Provider value={value} {...props} />
}
