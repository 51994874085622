import * as React from "react";

import {
  makeStyles,
  Body1,
  Caption1,
  Button,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  Card,
  CardFooter,
  CardHeader,
} from "@fluentui/react-components";
import { useRadiusContext as useContext } from "../../Context/RadiusContext";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const SettingsCard = () => {
  const {serviceOffer, caCertificateDownload, clientCertificateDownload} = useContext();
  const styles = useStyles();

  return (
    <Card className={styles.card}>
      <CardHeader
        header={
            <Body1>
                <b>Information and Settings</b>
            </Body1>
        }
        description={
            <Caption1>
                {serviceOffer?.displayname}
            </Caption1>
        }
      />
        <table className={styles.propsTable}>
          <tbody>
            <tr>
                <td>Radsec service uri</td>
                <td>radsec.modernmanaged.services</td>
            </tr>
            <br></br>
            <tr>
                <td>Radsec Client Certificate</td>
                <td>
                <Button onClick={clientCertificateDownload} shape="circular">Download</Button>
                </td>
            </tr>
            <tr>
                <td>Radsec CA certificate</td>
                <td><Button onClick={caCertificateDownload} shape="circular">Download</Button></td>
            </tr>
          </tbody>
        </table>
      <CardFooter>
      </CardFooter>
    </Card>
  );
};
