import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';

// Service Management
import {default as ServicesManagement} from '../Services/Management';

// RADIUS Service
import {default as RadiusManagement} from '../Radius/Management';

// AZTA Service
import {default as AZTAManagement} from '../AZTA/Management';

// Applications Management Service
import {default as ApplicationsManagement} from '../Applications/Management';

// MSCSP Management Service
import {default as MSCSPManagement} from '../MSCSP/Management';

// Asset Management
import {default as AssetManagement} from '../Asset/Management';

// Assessment Management
import {default as AssessmentManagement} from '../Assessment/Management';

// Profile
import {default as Profile} from '../Avatar/Profile';

// Import Context
import { useClientprincipal } from '../../Context/ClientprincipalContext'


function MainPane() {
  const {clientprincipal} = useClientprincipal()

  if (!clientprincipal.isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element ={<Home />}/>
        <Route path="/profile" element ={<Profile />}/>
        <Route path="/home" element ={<Home />}/>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element ={<Home />}/>
      <Route path="/home" element ={<Home />}/>
      <Route path="/profile" element ={<Profile />}/>
      <Route path="/services/management" element ={<ServicesManagement />}/>
      <Route path="/applications/management" element ={<ApplicationsManagement />}/>
      <Route path="/asset/Management" element ={<AssetManagement />}/>
      <Route path="/azta/management" element ={<AZTAManagement />}/>
      <Route path="/mscsp/management" element ={<MSCSPManagement />}/>
      <Route path="/radius/management" element ={<RadiusManagement />}/>
      <Route path="/assessment/management" element ={<AssessmentManagement />}/>
    </Routes>
  );
}

export default MainPane;