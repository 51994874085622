import * as React from "react";
import {
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
  Button,
} from "@fluentui/react-components";
import { Link } from "@fluentui/react-components";
import type { LinkProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const ApprovAccess = (props: LinkProps & { as?: "a" }) => (
  <Link href="https://login.microsoftonline.com/common/adminconsent?client_id=dbc17e17-4087-4e57-aea7-11405cd4eefe" as="a" target="_blank">
    <Button shape="circular" appearance="outline">Approve Access</Button>
  </Link>
);

const CopyLink = () => (
  <Button shape="circular" appearance="outline" onClick={() => {navigator.clipboard.writeText("https://login.microsoftonline.com/common/adminconsent?client_id=dbc17e17-4087-4e57-aea7-11405cd4eefe")}}>
    Copy Link
  </Button>
)

export const Faq = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
        <LargeTitle>Instructions</LargeTitle>
        <Body1 align="justify">
          This guide describes the configuration steps needed to enable the CSP Subscription Management service.
          <br></br>
          The service can manage selected CSP subscriptions based on group member count and a offset.
          <br></br>
          You need Global Admin or equivalent priviledges to accept and consent to the Enterprise Application utilized by 
          <br></br>
          the service. The Enterprise Application is used by the service to read selected groups and their group member counts.
        </Body1>

        <Title3>1. Approve the 'MMS: CSP Subscription Management' Enterprise application.</Title3>
        <Body1 align="justify">
          Allow the CSP Subscription Management service access to selected groups in your EntraId tenant by approving the enterprise application.
          <br></br>
          Note: When the permissions been accepted you will be redirected to a blank page and can close it.
        </Body1>
        <Body1 align="start">
          <ApprovAccess /> <CopyLink />
        </Body1>
        <Title3>2. Assign 'MMS: CSP Subscription Management' owner access to groups</Title3>
        <Subtitle1>Prepare license groups</Subtitle1>
        <Body1 align="justify">
          Add the 'MMS: CSP Subscription Management' service principle as group owner to 'license groups' that will be used to manage your subscriptions.
          <br></br>
          When you try to add the Service Principal to a group as owner you can search for MMS: CSP Subscription Management or dbc17e17-4087-4e57-aea7-11405cd4eefe to find it
        </Body1>
        <Subtitle1>Important!</Subtitle1>
        <Body1 align="justify">
          <li>If the service cannot access the Service Principal in your Azure tenant or if Service Principal is not added as owner to at least one license group the service cannot be enabled.</li>
          <li>The CSP subscriptions that you want to managed must also be purchased from Atea through Atea Cloud Portal.</li>
          <li>Do not remove the Service Principal as owner of a license group if you have connected it to an subscription in the service portal.</li>
          <li>Remove it first in the service portal before removing the Service Principal as owner of a license group.</li>
        </Body1>
      </div>
    )
};

export default Faq;