import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Divider,
  shorthands,
  tokens,
  makeStyles,
} from "@fluentui/react-components";
import { 
    InfoRegular,
  } from "@fluentui/react-icons";
import {
  IAuditlogItem,
} from '../../Context/ServiceContext'

const useStyles = makeStyles({
  /*
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  */
  propsTable: {
    "& th": {
      alignContent: "flex-start",
    },
    "& td": {
      // ...shorthands.padding(0, "30px", 0, 0), // top, right, bottom, left
      ...shorthands.padding(0, 0, 0, "20px"),
      maxWidth: "380px",
      wordWrap: "break-word",
    },
  },
});

export const OpenAuditLog = (props: IAuditlogItem) => {
  const styles = useStyles();

  return (
    <Dialog modalType="alert" >
      <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<InfoRegular />} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {props.activitytype} {props.category} {props.status}
            <Divider>Updated by: {props.userprincipalname} @ {(new Date(Date.parse(props.activitytimestamp))).toLocaleDateString('sv-SE')}</Divider>
          </DialogTitle>
          <DialogContent>
            <br></br>
            <table className={styles.propsTable}>
              <tr><th>Activity</th><td>{props.activitytype}</td></tr>
              <tr><th>Date</th><td>{(new Date(Date.parse(props.activitytimestamp))).toLocaleDateString('sv-SE')}</td></tr>
              <tr><th>Category</th><td>{props.category}</td></tr>
              <tr><th>Status</th><td>{props.status}</td></tr>
              <tr><th>Status reason</th><td>{props.statusreason}</td></tr>
              <tr><th>Userprincipalname</th><td>{props.userprincipalname}</td></tr>
              <tr><th>Displayname</th><td>{props.displayname}</td></tr>
              <tr><th>User id</th><td>{props.uid}</td></tr>
              <tr><th>Microsoft EntraID</th><td>{props.tid}</td></tr>
              <tr><th>Target id</th><td>{JSON.parse(props?.targets)?.id}</td></tr>
              <tr><th>Target new</th><td>{JSON.stringify(JSON.parse(props?.targets)?.newobject)}</td></tr>
              <tr><th>Target old</th><td>{JSON.stringify(JSON.parse(props?.targets)?.oldobject)}</td></tr>
            </table>
            <br></br>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button shape="square" appearance="outline">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};