import * as React from "react";
import {
  ArrowSync24Regular,
  Dismiss24Regular,
  PresenceAvailableRegular,
  WarningRegular,
  CheckmarkCircleWarningRegular,
  TopSpeedRegular,
  //GaugeRegular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
  SearchBox,
} from "@fluentui/react-components";
// Drawer Code
import {useRef} from "react";
import { 
  Text,
} from "@fluentui/react-components";
import { 
} from "@fluentui/react-icons";
import { 
  Switch,
  SwitchProps,
} from "@fluentui/react-components";
import {
  shorthands,
} from "@fluentui/react-components";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Tab,
  TabList,
  TextareaProps,
  Textarea,
  InputProps,
  Input,
} from "@fluentui/react-components";
import { 
  Caption2,
  Body1,
  Card,
  CardHeader,
} from "@fluentui/react-components";
// End Drawer Code

import type { SearchBoxProps } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import { Button, tokens } from "@fluentui/react-components";
//import { Checkbox } from "@fluentui/react-components";
//import { TextColumnThree24Regular } from "@fluentui/react-icons";
import { Field } from "@fluentui/react-components";
// import {OpenAssessmentItemNow as OpenItemNow} from './Drawer'
import { 
  useAssessmentContext as useContext,
  IAssessmentItem as IGridItem,
  IAssessmentItem
} from '../../Context/AssessmentContext'
import {SortString, SortBoolean} from "../../Functions/sorting";

const defaultColumns = ['displayname','compliant','score','product','category','isaddressed','isaccepted'];
const searchPropertyName = 'displayname'
const sortAscending = true
const sortColumn = "displayname"

const ColumnNameToDisplayName = (name:string) => {
  switch(name) {
    case 'displayname': return 'Displayname';
    case 'compliant': return 'Compliant';
    case 'score': return 'Score';
    case 'product': return 'Product';
    case 'category': return 'Category';
    case 'isaddressed': return 'To Address';
    case 'isaccepted': return 'Accepted';
    
    default:
      return name;
  }
}

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "displayname",
    compare: (a, b) => {
      return SortString(a.displayname,b.displayname);
    },
    renderHeaderCell: () => {
      return "Recommended Action";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          { item.displayname }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "id",
    compare: (a, b) => {
      return SortString(a.id,b.id);      
    },
    renderHeaderCell: () => {
      return "Id";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.id}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "product",
    compare: (a, b) => {
      return SortString(a.product,b.product);
    },
    renderHeaderCell: () => {
      return "Product";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.product}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "category",
    compare: (a, b) => {
      return SortString(a.category,b.category);
    },
    renderHeaderCell: () => {
      return "Category";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.category }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "compliant",
    compare: (a, b) => {
      return SortBoolean(a.compliant,b.compliant);
    },
    renderHeaderCell: () => {
      return "Compliant";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.compliant === true ? (
              <PresenceAvailableRegular color="Green" fontSize={24}/>
            ) : (
              item.isaccepted === true ? (
                <CheckmarkCircleWarningRegular color="Green" fontSize={24}/>
              ) : (
                <WarningRegular color="Orange" fontSize={24}/>
              )
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "score",
    compare: (a, b) => {
      return (((a.scoreachived/100)*a.scoremax) - ((b.scoreachived/100)*b.scoremax));
    },
    renderHeaderCell: () => {
      return "Score";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { (Number(Math.round(item.scoreachived*item.scoremax)/100)) + '/' + item.scoremax }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "isaddressed",
    compare: (a, b) => {
      return SortBoolean(a.isaddressed,b.isaddressed);
    },
    renderHeaderCell: () => {
      return "To address";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.isaddressed === true && (
              <CheckmarkCircleWarningRegular color="Orange" fontSize={24}/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "isaccepted",
    compare: (a, b) => {
      return SortBoolean(a.isaccepted,b.isaccepted);
    },
    renderHeaderCell: () => {
      return "Accepted";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.isaccepted === true && (
              <CheckmarkCircleWarningRegular color="Green" fontSize={24}/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  displayname: {
    minWidth: 250,
    defaultWidth: 400,
    idealWidth: 400,
  },
  product: {
      minWidth: 80,
      defaultWidth: 120,
  },
  category: {
    minWidth: 60,
    defaultWidth: 80,
  },
  compliant: {
    minWidth: 60,
    defaultWidth: 60,
  },
  score: {
    minWidth: 40,
    defaultWidth: 40,
  },
  isaddressed: {
    minWidth: 60,
    defaultWidth: 80,
  },
  isaccepted: {
    minWidth: 60,
    defaultWidth: 80,
  }
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
  table: {
    width: "fit-content"
  }
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  }
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

// Drawer Code
const useDrawerTabsStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "hidden",
    overflowY: "auto",
  },
  input: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "300px",
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "700px",
  },
  button: {
    display: "flex",
    justifyContent: "left",
  },
});

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});
// End Drawer Code

export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const {assessmentItems, isLoadingAssessmentItems, refreshAssessmentItems} = useContext();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns
      .includes(o.columnId.toString())
    )))
  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);
  const [totalScore, setTotalScore] = React.useState<number>(0);
  const [selectedItem, setSelectedItem] = React.useState<IAssessmentItem>();
  
  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 40) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  // Refresh AssessmentItems (isLoadingAssessmentItems)
  const RefreshButton = () => {
    const styles = useDrawerStyles();

    if (!isLoadingAssessmentItems) {
      return (
        <div className={styles.content}>
          <Button
            shape="square"
            appearance="subtle"
            aria-label="Refresh"
            icon={<ArrowSync24Regular />}
            onClick={refreshAssessmentItems}
            size="medium"
          >Refresh</Button>
        </div>
      )
    } else {
      return (
        <div className={styles.content}>
          <Button
            shape="square"
            appearance="subtle"
            aria-label="Refresh"
            icon={<ArrowSync24Regular className="Spinner-icon" />}
            size="medium"
          >Refresh</Button>
        </div>
      )
    }
  }

  const Score = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        <Button
          shape="square"
          appearance="subtle"
          aria-label="Total score"
          icon={<TopSpeedRegular />}
          size="medium"
        >Score: {Math.round(totalScore)}</Button>
      </div>
    )
  }
  
  // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter (searchPropertyName)
  React.useEffect(() => {
    // Check that the assessment array is present.
    if (Array.isArray(assessmentItems)) {
      
      // calculate total score
      if (Array.isArray(assessmentItems) && assessmentItems?.length > 0 ) {
        setTotalScore(assessmentItems.map(item => ((item.scoreachived*item.scoremax)/100)).reduce((a, b) => a + b));
        //assessmentItems.reduce((total, item) => total = total + ((item.scoreachived*item.scoremax)/100,0)));
      } else {
        setTotalScore(0)
      }
      
      // Apply searchfilter
      if(searchFilter !== "") {
        setFilteredItems(
          assessmentItems.filter(item => 
            (
              (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
            )
          )
        )
      } else {
        setFilteredItems(assessmentItems)
      }
    } else {
      setFilteredItems([])
    }
  },[searchFilter, assessmentItems])
  
  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: sortColumn, sortDirection: (sortAscending) ? ("ascending") : ("descending")}), []
  );
  
  // Drawer Code
  const OpenItemNow = (props: IAssessmentItem) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isAddressed, setIsAddressed] = React.useState(props.isaddressed);
    const [isAccepted, setIsAccepted] = React.useState(props.isaccepted);
    const [selectedValue, setSelectedValue] = React.useState<TabValue>()
    const refAssignedto = useRef<string>(props.assignedto || '')
    const refNotes = useRef<string>(props.notes || '')
    const {patchAssessmentItem} = useContext();
    const applyStyles = useApplyButtonStyles();
    const cardStyles = useCardStyles();
    const drawerTabsStyles = useDrawerTabsStyles();
  
    const IsAddressed = React.memo((props: SwitchProps) => (
      <Switch label={"Address Recommendation"} checked={isAddressed} onChange={() => setIsAddressed(!isAddressed)} {...props} />
    ));
  
    const IsAccepted = React.memo((props: SwitchProps) => (
      <Switch label={"Accept as non-compliant."} checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} {...props} />
    ));
  
    const TopServiceCard =  React.useCallback(() => {
      
      return (
        <Card className={cardStyles.card}>
          <Body1>
              <div role="tabpanel" aria-labelledby="item">
              <table className={cardStyles.propsTable}>
                  <tbody>
                    <tr><td>Product</td><td>{props.product}</td></tr>
                    <tr><td>Category</td><td>{props.category}</td></tr>
                    <tr><td>Status</td><td>{(props.compliant === true) ? ("Compliant") : (
                      (props.isaccepted) ? ("Not compliant (Accepted)") : ("Not Compliant")
                    )}</td></tr>
                  </tbody>
                </table>
              </div>
              <br></br>
              { (props.compliant === false || props.isaddressed ) ? ( <IsAddressed /> ) : ( <IsAddressed disabled />) }
              <br></br>
              { (props.compliant === false || props.isaccepted ) ? ( <IsAccepted /> ) : ( <IsAccepted disabled />) }
              <br></br>
          </Body1>
        </Card>
      );
    },[props,IsAddressed,IsAccepted,cardStyles]);
  
    const GeneralCards =  React.useCallback(() => {
      
      return (
        <>
          <Card className={cardStyles.card}>
            <CardHeader
              header={
                <Body1>
                  <b>Description</b>
                </Body1>
              }
            />
            <Body1>
              {props.description.split("\n").map(row => (<>{row}<br/></>))}
            </Body1>
          </Card>
          <Card className={cardStyles.card}>
            <CardHeader
              header={
                <Body1>
                  <b>Implementation Status</b>
                </Body1>
              }
            />
            <Body1>
              {props.status.split("\n").map(row => (<>{row}<br/></>))}
            </Body1>
          </Card>
        </>
      );
    },[props.status,props.description,cardStyles]);
  
    const ImplementationCards =  React.useCallback(() => {
      
      return (
        <Card className={cardStyles.card}>
          <CardHeader
            header={
              <Body1>
                <b>Implementation</b>
              </Body1>
            }
          />
          <Body1>
            {props.implementation}
          </Body1>
        </Card>
      );
    },[props.implementation,cardStyles]);
  
    // On-click Close Button Function
    const onClickCloseButton = React.useCallback(() => {
      if (props['isaddressed'] !== isAddressed || props['isaccepted'] !== isAccepted || props['notes'] !== refNotes.current || props['assignedto'] !== refAssignedto.current) {
        console.log({
          ...props,
          notes: refNotes.current,
          assignedto: refAssignedto.current,
          isaddressed: isAddressed,
          isaccepted: isAccepted
        })
        patchAssessmentItem({
          ...props,
          notes: refNotes.current,
          assignedto: refAssignedto.current,
          isaddressed: isAddressed,
          isaccepted: isAccepted
        })
        // Remove item selection
      }
      setSelectedItem(undefined)
      setIsOpen(false);
    },[props, isAddressed, isAccepted, patchAssessmentItem]);
  
    const DrawerTabsWithPanels = (props: IAssessmentItem) => {
      
      const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
      };
    
      const GeneralPanel = React.memo(() => (
        <div role="tabpanel" aria-labelledby="General">
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <div className={drawerTabsStyles.container}>
                <GeneralCards />
              </div>
            </div>
          </Text>
        </div>
      ));
      
      const ImplementationPanel = React.memo(() => (
        <div role="tabpanel" aria-labelledby="Implementation">
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <div className={drawerTabsStyles.container}>
                <ImplementationCards />
              </div>
            </div>
          </Text>
        </div>
      ));
    
      const NotesPanel = React.memo(() => {
        const [notesValue, setNotesValue] = React.useState<string>(refNotes.current || '')
        const [assignedToValue, setAssignedToValue] = React.useState<string>(refAssignedto.current || '')
        const notesTextLimit = 200
        const assignedToTextLimit = 30
    
        const onChangeNotes: TextareaProps["onChange"] = (ev, data) => {
          if (data.value.length <= notesTextLimit) {
            setNotesValue(data.value);
            refNotes.current = data.value;
          }
        };
    
        const onChangeAssignedto: InputProps["onChange"] = (ev, data) => {
          if (data.value.length <= assignedToTextLimit) {
            setAssignedToValue(data.value);
            refAssignedto.current = data.value;
          }
        };
  
        return (
          <div role="tabpanel" aria-labelledby="Notes">
            <Text>
              <div role="tabpanel" aria-labelledby="item">
                <div className={drawerTabsStyles.container}>
                  <Card className={cardStyles.card}>
                    <CardHeader
                      header={
                        <Body1>
                          <b>Assigned to:</b>
                        </Body1>
                      }
                      description={
                        <Caption2>
                          {"(" + assignedToTextLimit + " characters)"}
                        </Caption2>
                      }
                    />
                    <div className={drawerTabsStyles.input} >
                      <Input value={assignedToValue} onChange={onChangeAssignedto} />
                    </div>
                  </Card>
                  <Card className={cardStyles.card}>
                    <CardHeader
                      header={
                        <Body1>
                          <b>Notes</b>
                        </Body1>
                      }
                      description={
                        <Caption2>
                          {"(" + notesTextLimit + " characters)"}
                        </Caption2>
                      }
                    />
                    <Textarea appearance="outline" resize="vertical" value={notesValue} onChange={onChangeNotes} rows={8} />
                  </Card>
                </div>
              </div>
            </Text>
          </div>
        )
      });
    
      const ActiveTabs = React.useCallback(() => {
        const defaultSelectedValue = 'general'
        if (!selectedValue) { setSelectedValue(defaultSelectedValue)}
        
        return (
          <>
            <TabList size="large" defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
              <Tab id="General" value="general">
                General
              </Tab>
              <Tab id="Implementation" value="implementation">
                Implementation
              </Tab>
              <Tab id="Notes" value="notes">
                Notes
              </Tab>
            </TabList>
            <div className="App-contentgrid">
              {selectedValue === "general" && <GeneralPanel />}
              {selectedValue === "implementation" && <ImplementationPanel />}
              {selectedValue === "notes" && <NotesPanel />}
            </div>
          </>    
        )
      },[GeneralPanel, ImplementationPanel, NotesPanel])
    
      return (
        <ActiveTabs />
      );
    };
  
    return (
      <div>
        <OverlayDrawer
          open={isOpen}
          position="end"
          size="large"
          onOpenChange={(_, { open }) => setIsOpen(open)}
          modalType="alert"
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={onClickCloseButton}
                />
              }
            >
              {props.displayname}
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <p>
            <Text>
              <div role="tabpanel" aria-labelledby="item">
                <TopServiceCard />
              </div>
            </Text>
            <br></br>
            <DrawerTabsWithPanels {...props}/>
            </p>
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
            </div>
          </DrawerBody>
        </OverlayDrawer>
      </div>
    );
  };

  // End Drawer Code
  const OpenSelectedItem = React.memo(() => {
    if (selectedItem) {
      return( <OpenItemNow {...selectedItem}/> )
    } else {
      return(<></>)
    }
  })

  // GridArea (isLoading).
  const GridArea = React.useCallback(() => {
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
      if (selectedItem === assessmentItems.find((element: IAssessmentItem) => { return element.id === data.selectedItems.values().next().value;})) {
        setSelectedItem(undefined)
        console.log('undefined')
      } else {
        setSelectedItem(
          assessmentItems
          .find((element: IAssessmentItem) => { 
            return element.id === data.selectedItems.values().next().value;
          })
        )
        console.log(
          assessmentItems
          .find((element: IAssessmentItem) => { 
            return element.id === data.selectedItems.values().next().value;
          })
        )
      }
    }

    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.id}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
          subtleSelection
          selectionAppearance="neutral"
          selectionMode="single"
          onSelectionChange={onSelectionChange}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          <OpenSelectedItem />
          {
            (!isLoadingAssessmentItems) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <div className='App-tablearea'>
                <Spinner label="Loading..." labelPosition="after"/>
              </div>
            )
          }
        </DataGrid>
      </div>
    )
  },[assessmentItems,OpenSelectedItem,selectedItem,filteredItems,showColumns,isLoadingAssessmentItems,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+assessmentItems.length+")" : "Input is limited to 40 characters."}
        >
          <table className={styles.table}>
            <tbody>
              <tr>
                <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
                <td><RefreshButton /></td>
                <td><Score /></td>
              </tr>
            </tbody>
          </table>
        </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;