import * as React from "react";
import {
  Tab,
  TabList,
  Spinner,
} from "@fluentui/react-components";
import {
  Settings24Filled,
  Settings24Regular,
  PhoneLaptop24Regular,
  PhoneLaptop24Filled,
  LocationAdd24Regular,
  LocationAdd24Filled,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as Faq } from './Faq'
import { default as AssetManagement } from './AssetManagement'
import { default as LocationManagement } from './LocationManagement'
import { default as Settings } from './Settings'
import { useAssetContext as useContext} from "../../Context/AssetContext";

const SettingsIcons = bundleIcon(Settings24Filled, Settings24Regular)
const FaqIcons = bundleIcon(DocumentOnePageMultiple24Filled, DocumentOnePageMultiple24Regular);
const AssetIcons = bundleIcon(PhoneLaptop24Filled, PhoneLaptop24Regular);
const LocationIcons = bundleIcon(LocationAdd24Filled, LocationAdd24Regular);

const TabsWithPanels = () => {
  const {subscription} = useContext();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>()
  
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };
  
  const SettingsPanel = React.memo(() => (
    <div role="tabpanel" aria-labelledby="Settings">
      <Settings />
    </div>
  ));
  
  const FaqPanel = React.memo(() => (
    <Faq />
  ));

  const AssetPanel = React.memo(() => (
    <AssetManagement />
  ));

  const LocationPanel = React.memo(() => (
    <LocationManagement />
  ));

  const ActiveTabs = React.useCallback(() => {
    const defaultSelectedValue = (subscription?.isEnabled) ? ( 'assets' ) : ( 'faq' )
    if (!selectedValue) { setSelectedValue(defaultSelectedValue)}
    
    return (
      <>
      {(subscription?.isEnabled) ? (
        <>
        <TabList defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
          <Tab id="Settings" icon={<SettingsIcons />} value="settings">
            Settings
          </Tab>
          <Tab id="FAQ" icon={<FaqIcons />} value="faq">
            FAQ
          </Tab>
          <Tab id="Assets" icon={<AssetIcons />} value="assets">
            Assets
          </Tab>
          <Tab id="Locations" icon={<LocationIcons />} value="locations">
            Locations
          </Tab>
        </TabList>
        <div className="App-contentgrid">
          {selectedValue === "faq" && <FaqPanel />}
          {selectedValue === "settings" && <SettingsPanel />}
          {selectedValue === "assets" && <AssetPanel />}
          {selectedValue === "locations" && <LocationPanel />}
        </div>
        </>
      ) : (
        <>
        <TabList defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
          <Tab id="Settings" icon={<SettingsIcons />} value="settings">
            Settings
          </Tab>
          <Tab id="FAQ" icon={<FaqIcons />} value="faq">
            FAQ
          </Tab>
        </TabList>
        <div className="App-contentgrid">
          {selectedValue === "faq" && <FaqPanel />}
          {selectedValue === "settings" && <SettingsPanel />}
        </div>
        </>
      )}
      </>    
    )
  },[subscription, selectedValue, AssetPanel, FaqPanel, LocationPanel, SettingsPanel])

  return (
    (!subscription) ? (
      <Spinner size="large" label="Fetching subscription..." labelPosition="below"/>
    ) : (
      <ActiveTabs />
    )
  );
};

export default TabsWithPanels;
