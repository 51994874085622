import React from 'react';
import './Components/Layout/Webportal.css';
import { Navigation } from './Components/Layout/Navigation';
//import { teamsLightTheme, teamsDarkTheme, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import { FluentProvider } from '@fluentui/react-components';
import { ThemeProvider, useTheme } from './Context/ThemeContext'
import {default as MainComponent} from './Components/Layout/MainComponent'
import {default as HeaderPane} from './Components/Layout/HeaderPane'
import {default as HeaderNavPane} from './Components/Layout/HeaderNavPane'


// Apply Theme
const LayoutWithTheme = () => {
  const {theme } = useTheme()

  return (
    <FluentProvider theme={theme.theme}>
      <div className="App-container">
        <header>
          <HeaderPane />
        </header>
        <navheader>
          <HeaderNavPane />
        </navheader>
        <nav>
          <Navigation />
        </nav>
        <main>
          <MainComponent />
        </main>
      </div>
    </FluentProvider>
  )
}

const App = () => (
  <ThemeProvider>
      <LayoutWithTheme />
  </ThemeProvider>
);

export default App;