import * as React from "react";
import { default as TabsWithPanels } from './TabsWithPanels'
import { AssessmentProvider as Provider} from '../../Context/AssessmentContext'

export const WithPanels = () => {
  return (
    <Provider>
      <TabsWithPanels />
    </Provider>
  );
};

export default WithPanels;