import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer, IServiceManagementItem } from "./ClientprincipalContext";

export interface ISettingsObject {
  notificationEmail: string | undefined;
}

export const DefaultISettingsObject = {
  notificationEmail: undefined,
};

// Log  item
export interface ILogItem {
  groupDisplayName: string;
  groupId: string;
  groupMemberCount: number;
  logDateTime: string;
  nextTermQuantity: number;
  offerName: string;
  offset: number;
  orderQuantity: number;
  previousQuantity: number;
  quantity: number;
  refundableTotalQuantity: number;
  subscriptionId: string;
  status: string;
}

// Auditlog item
export interface IAuditlogItem {
  id: string; // logid
  activitytimestamp: string;
  activitytype: string;
  category: string;
  status: string;
  statusreason: string;
  userprincipalname: string;
  displayname: string;
  uid: string;
  tid: string;
  targets: string;
}

// Subscription item
export interface ICspSubscriptionItem {
  id: string; // subscriptionId
  quantity: number;
  offerName: string;
  groupid: string;
  offset: number;
  approvedby: string;
  approvalDateTime: string;
  groupDisplayname: string;
  groupMemberCount: number;
}

// Group item assigned to a subscription
export interface IGroupItem {
  id: string; // groupId
  displayName: string;
  groupMemberCount: number;
}

export type MSCSPContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: IServiceManagementItem | undefined;
  subscriptions: ICspSubscriptionItem[];
  logs: ILogItem[];
  auditlogItems: IAuditlogItem[];
  groups: IGroupItem[];
  isLoading: boolean;
  isLoadingLogs: boolean;
  isLoadingAuditlog: boolean;
  settings: ISettingsObject | undefined;
  patchManagedSubscription: (props: ICspSubscriptionItem) => void;
  deleteManagedSubscription: (props: ICspSubscriptionItem) => void;
  refreshSubscriptions: () => void;
  refreshLogs: () => void;
  getAuditlogItems: () => void;
  mergeSettings: (props: Partial<ISettingsObject>) => void;
};

const MSCSPDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  subscriptions: [],
  logs: [],
  auditlogItems: [],
  groups: [],
  isLoading: false,
  isLoadingLogs: false,
  isLoadingAuditlog: false,
  settings: undefined,
  patchManagedSubscription: (props: ICspSubscriptionItem) => {},
  deleteManagedSubscription: (props: ICspSubscriptionItem) => {},
  refreshSubscriptions: () => {},
  refreshLogs: () => {},
  getAuditlogItems: () => {},
  mergeSettings: (props: Partial<ISettingsObject>) => {},
};

export interface IProviderProps {
children?: any;
}

const MSCSPContext = React.createContext<MSCSPContextType>(MSCSPDefaultValue)

export const useMSCSPContext = () => {
  const context = React.useContext(MSCSPContext);
  if (!context) {
    throw new Error(
      `useAssetContext must be used within a AssetProvider`
    )
  }
  return context;
}

export const MSCSPProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [subscriptions, setSubscriptions] = React.useState<ICspSubscriptionItem[]>([]);
  const [logs, setLogs] = React.useState<ILogItem[]>([]);
  const [auditlogItems, setAuditlogItems] = React.useState<IAuditlogItem[]>([]);
  const [groups, setGroups] = React.useState<IGroupItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingLogs, setIsLoadingLogs] = React.useState<boolean>(false)
  const [isLoadingAuditlog, setIsLoadingAuditlog] = React.useState<boolean>(false)
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
  const [settings, setSettings] = React.useState<ISettingsObject | undefined>(undefined)

  const refreshLogs = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      // Get Logs
      setIsLoadingLogs(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/mscsp/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => setLogs(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingLogs(false);
      })
      
    } else {
      setLogs([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const getAuditlogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      // Get Logs
      setIsLoadingAuditlog(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/mscsp/auditlog', requestOptions)
      .then((response) => response.json())
      .then((result) => setAuditlogItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingAuditlog(false);
      })
      
    } else {
      setAuditlogItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const refreshSubscriptions = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      // Get Subscriptions
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json', 
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubscriptions(result.subscriptions)
        setGroups(result.groups)
      })
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
      
    } else {
      setSubscriptions([])
      setGroups([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const patchManagedSubscription =  React.useCallback((props: ICspSubscriptionItem) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      
      // Patch location
      console.log(JSON.stringify(props))
      const requestOptions = {
        method: 'PUT',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
        body: JSON.stringify(
          {
            id: props.id,
            offerName: props.offerName,
            groupId: props.groupid,
            offset: props.offset
          }),
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshSubscriptions();
      })

    } else {
      alert('An error occured when updating object.')
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName, refreshSubscriptions])

  const deleteManagedSubscription = React.useCallback((props: Partial<ICspSubscriptionItem>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      
      // Patch subscription management object
      console.log('deleteManagedSubscription: ' + props.id)
      const requestOptions = {
        method: 'DELETE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
        body: JSON.stringify({id: props.id}),
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshSubscriptions();
      })

    } else {
      alert('An error occured when deleting object.')
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName, refreshSubscriptions])
  
  const getSettings = React.useCallback(() => {
      if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
          && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
          && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/mscsp/settings', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISettingsObject) : (undefined))))
      .then((result) => {setSettings(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const mergeSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    console.log('mergeSettings')
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){

      console.log(settings)
      console.log(props)
      console.log('Updating settings')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          notificationEmail:props.notificationEmail
        }),
      };
      fetch('/api/mscsp/settings', requestOptions)
      .then((response) => (response.status === 204) ? (
        getSettings()
      ) : (
        console.log('Failed to set settings.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName, settings, getSettings])

  // Init subscriptions and group array
  React.useEffect(() => {
    refreshSubscriptions();
  },[clientprincipal, refreshSubscriptions]);

  // Get log items
  React.useEffect(() => {
    refreshLogs();
  },[clientprincipal, refreshLogs]);

  // Get log items
  React.useEffect(() => {
    getAuditlogItems();
  },[clientprincipal, getAuditlogItems]);

  // Get settings
  React.useEffect(() => {
    getSettings();
  }, [clientprincipal, getSettings]);

  // Set servciceOffer
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "mscsp";
      }))
  },[serviceOffers, setServiceOffer]);

  // Set subscription
  React.useEffect(() => {
    setSubscription(
      serviceSubscriptions.find((element: IServiceManagementItem) => {
        return element.name === "mscsp";
      })
    )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({serviceOffer, subscription, subscriptions, logs, auditlogItems, groups, isLoading, isLoadingLogs, isLoadingAuditlog, settings, patchManagedSubscription, deleteManagedSubscription, refreshSubscriptions, refreshLogs, getAuditlogItems, mergeSettings}), [serviceOffer, subscriptions, subscription, logs, auditlogItems, groups, isLoading, isLoadingLogs, isLoadingAuditlog, settings,  patchManagedSubscription, deleteManagedSubscription, refreshSubscriptions, refreshLogs, getAuditlogItems, mergeSettings])
  return <MSCSPContext.Provider value={value} {...props} />
}
