import homeimage from '../../Images/AssetManagement.png';
import {
  Image,
  makeStyles,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  imagecontainer: {
    height: "70vmin",
    pointerevents: "none",
  },
});

function Home() {
  const style = useStyles();
  return (
    <div className={style.imagecontainer}>
      <Image src={homeimage} fit="contain" alt="homeimage" />
    </div>
  );
}

export default Home;