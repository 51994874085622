import * as React from "react";

import {
  makeStyles,
  Body1,
  Caption1,
  Button,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  Card,
  CardFooter,
  CardHeader,
} from "@fluentui/react-components";
import { 
    Save24Regular,
    Dismiss24Regular,
    Edit24Regular,
} from "@fluentui/react-icons";
import {
  InputProps,
  Field,
  Input,
} from "@fluentui/react-components";
import { useMSCSPContext as useContext} from "../../Context/MSCSPContext";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const SettingsCard = () => {
  const {serviceOffer} = useContext();
  const styles = useStyles();

  // Settings Management
  const {settings, mergeSettings} = useContext();
  const email_regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/; 
  const [isEdit , setIsEdit] = React.useState<boolean>(false);
  const [emailValue, setEmailValue] = React.useState<string>(settings?.notificationEmail || '');
  const [emailValid, setEmailValid] = React.useState<boolean>(((emailValue.length === 0) || (emailValue || '').match(email_regex))? (true) : (false));

  const onClickSaveEmailSettings = React.useCallback(() => {
    mergeSettings(
      {
        notificationEmail: emailValue,
      })
  }, [emailValue, mergeSettings]);
  
  const EmailSettingsButton = React.useCallback(() => {
    return (
      (isEdit) ? (
        <>
        <Button icon={<Dismiss24Regular />} shape="square" appearance="subtle" onClick={onClickSaveEmailSettings}>Cancel</Button>
        {
          ((emailValid) && (settings?.notificationEmail !== emailValue)) ? (
              <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveEmailSettings}>Save</Button>
          ) : (
            <Button icon={<Save24Regular />} shape="square" appearance="subtle" disabled >Save</Button>
          )
        }
        </>
      ) : (
        <Button icon={<Edit24Regular />} shape="square" appearance="subtle" onClick={() => setIsEdit(true) }>Edit</Button>
      )
    )
  },[isEdit, emailValid, emailValue, onClickSaveEmailSettings, settings])

  const onEmailChange: InputProps["onChange"] = (ev, data) => {
    if (data.value.length <= 50) {
      setEmailValue(data.value);
    }

    // Validation rule
    if (data.value.length === 0 || data.value.match(email_regex)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }

  return (
      <Card className={styles.card}>
        <CardHeader
          header={
              <Body1>
                    <b>
                    Settings
                  </b>
              </Body1>
          }
          description={
              <Caption1>
                  {serviceOffer?.displayname}
              </Caption1>
          }
        />
        <table className={styles.propsTable}>
          <tbody>
            <tr>
              <Field 
                label="Service notification e-mail address"
                validationState={emailValid ? "success" : "warning"}
                validationMessage={emailValid ? (emailValue.length === 0 ? "Optional" : "") : "Optional: Limited to 50 characters."}
                >
                <Input value={emailValue} onChange={onEmailChange} disabled={!isEdit} />
              </Field>
            </tr>
          </tbody>
        </table>
        <CardFooter>
          <EmailSettingsButton />
        </CardFooter>
      </Card>
  );
};
