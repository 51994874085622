import * as React from "react";
import {
  Tab,
  TabList,
  SpinnerProps,
  Spinner,
} from "@fluentui/react-components";
import {
  Apps24Filled,
  Apps24Regular,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  DatabaseSearch24Filled,
  DatabaseSearch24Regular,
  MoneyCalculatorFilled,
  MoneyCalculatorRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as ServiceManagement } from './ServiceManagement'
import { default as SigninLog } from './SigninLog'
import { default as SubscriptionLog } from './SubscriptionLog'
import { default as AuditLog } from './AuditLogs'
import { default as Invoices } from './Invoices'

const AppsIcons = bundleIcon(Apps24Filled, Apps24Regular);
const LogsIcons = bundleIcon(DatabaseSearch24Filled, DatabaseSearch24Regular);
const InvoiceIcons = bundleIcon(MoneyCalculatorFilled, MoneyCalculatorRegular);

export const DefaultSpinner = (props: Partial<SpinnerProps>) => <Spinner {...props} />;

export const TabsWithPanels = () => {
  const defaultSelectedValue = 'services'
  const [selectedValue, setSelectedValue] = React.useState<TabValue>(defaultSelectedValue)
  
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const InvoicesPanel = React.memo(() => (
    <Invoices />
  ));

  const ServiceManagementPanel = React.memo(() => (
      <ServiceManagement />
  ));

  const SigninLogPanel = React.memo(() => (
    <SigninLog />
  ));

  const SubscriptionLogPanel = React.memo(() => (
    <SubscriptionLog />
  ));

  const AuditLogPanel = React.memo(() => (
    <AuditLog />
  ));

  const ActiveTabs = React.useCallback(() => {
    return (
      <>
        <TabList defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
          <Tab id="Services" icon={<AppsIcons />} value="services">
            Services
          </Tab>
          <Tab id="Invoices" icon={<InvoiceIcons />} value="invoices">
            Invoices
          </Tab>
          <Tab id="Sign-In log" icon={<LogsIcons />} value="signinlog">
            Sign-In log
          </Tab>
          <Tab id="Subscription log" icon={<LogsIcons />} value="subscriptionlog">
            Subscriptions log
          </Tab>
          <Tab id="Audit log" icon={<LogsIcons />} value="auditlog">
            Audit log
          </Tab>
        </TabList>
        <div className="App-contentgrid">
          {selectedValue === "services" && <ServiceManagementPanel />}
          {selectedValue === "invoices" && <InvoicesPanel />}
          {selectedValue === "signinlog" && <SigninLogPanel />}
          {selectedValue === "subscriptionlog" && <SubscriptionLogPanel />}
          {selectedValue === "auditlog" && <AuditLogPanel />}
        </div>
      </>
    )
  },[defaultSelectedValue, selectedValue, ServiceManagementPanel, InvoicesPanel, SigninLogPanel, SubscriptionLogPanel, AuditLogPanel])

  return (
    <ActiveTabs />
  );
};

export default TabsWithPanels;
