import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import * as React from "react";
import { 
  Button,
  Text,
  makeStyles,
  shorthands,
  tokens,
  Switch,
  SwitchProps,
} from "@fluentui/react-components";
import { 
  Card,
  CardHeader,
  Body1,
  LargeTitle,
  Subtitle1,
  Subtitle2,
} from "@fluentui/react-components";
import { 
  Dismiss24Regular,
  ArrowSync24Regular,
  Edit24Regular,
  BookInformation24Regular,
} from "@fluentui/react-icons";
import {
  useApplicationContext as useContext,
  IApplicationItem,
  IWinGetApplicationItem
} from '../../Context/ApplicationContext'
import type { ILogItem } from "../../Context/ApplicationContext";

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

// Open Application Drawer
export const OpenApplication = (props: IApplicationItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [general, setGeneral] = React.useState<boolean>(props['isGeneral']);
  const [update, setUpdate] = React.useState<boolean>(props['isUpdate']);
  const [preview, setPreview] = React.useState<boolean>(props['isPreview']);
  const {setApplication, synchronizeApplication} = useContext();
  const [syncTriggered, setSyncTriggered] = React.useState(false);
  const applyStyles = useApplyButtonStyles();
  const cardStyles = useCardStyles();

  const onClickApplyButton = React.useCallback(() => {
    if (  props['isGeneral'] !== general ||
          props['isPreview'] !== preview ||
          props['isUpdate'] !== update) {

      console.log('Set: appid ('+props.id+')')
      setApplication(
        {
          id: props.id,
          isGeneral: general,
          isUpdate: update,
          isPreview: preview,
        }
      )
    }
    
    setIsOpen(false);
  }, [general, preview, update, props, setApplication]);

  const syncApplication = React.useCallback(() => {
    console.log('syncing: appid ('+props.id+')')
    setSyncTriggered(true)
    synchronizeApplication(
      {
        id: props.id,
        isGeneral: general,
        isUpdate: update,
        isPreview: preview,
      }
    )
  }, [general, preview, update, props.id, synchronizeApplication]);

  const SyncButton = React.useCallback(() => {
    return (
      <div>
        <Button
          shape="square"
          appearance="subtle"
          aria-label="Synchronize Application"
          icon={<ArrowSync24Regular />}
          onClick={syncApplication}
          size="medium"
          disabled={syncTriggered}
        >{(syncTriggered) ? ("Application synchronization triggered") : ("Synchronize Application")}</Button>
      </div>
    )  
  }, [syncTriggered, syncApplication])

  const ApplicationCard =  React.useCallback(() => {

    const General = (props: SwitchProps) => (
      <Switch label={"General Version"} checked={general} onChange={() => setGeneral(!general)} {...props} />
    );
  
    const Update = (props: SwitchProps) => (
      <Switch label={"Update Version"} checked={update} onChange={() => setUpdate(!update)} {...props} />
    );
    const Preview = (props: SwitchProps) => (
      <Switch label={"Preview Version"} checked={preview} onChange={() => setPreview(!preview)} {...props} />
    );

    return (
      <Card className={cardStyles.card}>
        {
          <CardHeader
            image = {
              (props.largeIcon) && (
                <img src={`data:${JSON.parse(props.largeIcon).type};base64,${JSON.parse(props.largeIcon).value}`} width="150px" height="auto" alt="logo" />
              )
            }
            header={
              <Text>
                <LargeTitle>
                  {props.displayName}
                </LargeTitle>
                <br></br>
                <Body1>
                  {props.vendor}
                </Body1>
              </Text>
            }
          />
        }
        <Body1>
          <br></br>
            <div role="tabpanel" aria-labelledby="item">
              <table>
                <tbody>
                  <tr>
                    <td className={cardStyles.propsTable}>
                      <tr><td>Name</td><td>{props.displayName}</td></tr>
                      <tr><td>Vedor</td><td>{props.vendor}</td></tr>
                      <tr><td>Type</td><td>{(props.isPublic)?('Standard Application'):('Custom Application')}</td></tr>
                    </td>
                    <td className={cardStyles.propsTable}>
                      <tr><td>General version</td><td>{props.generalVersion}</td></tr>
                      <tr><td>Update version</td><td>{props.updateVersion}</td></tr>
                      <tr><td>Preview version</td><td>{props.previewVersion}</td></tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </Body1>
        <br></br>
        <Body1 italic align="justify">
          {props.description}
        </Body1>
        <br></br>
        <br></br>
        <Subtitle2 align="center">
          -- Application deployment settings --
        </Subtitle2>
          <table align="center">
            <tbody>
              <tr>
                <td><General /></td>
                <td><Update /></td>
                <td><Preview /></td>
              </tr>
            </tbody>
          </table>
          {
              (general || update || preview) && (
                <table align="center">
                  <tbody>
                    <tr>
                      <td><SyncButton /></td>
                    </tr>
                  </tbody>
                </table>
              )
            }
      </Card>
    );
  },[props, cardStyles, SyncButton, preview, general, update]);

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <div role="tabpanel" aria-labelledby="item">
            <ApplicationCard />
          </div>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};

export const OpenWinGetApp = (props: IWinGetApplicationItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {provisionWinGetApp} = useContext();
  const [syncTriggered, setSyncTriggered] = React.useState(false);
  const applyStyles = useApplyButtonStyles();
  const cardStyles = useCardStyles();

  const onClickCloseButton = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const provisionApplication = React.useCallback(() => {
    console.log('syncing: appid ('+props.id+')')
    setSyncTriggered(true)
    provisionWinGetApp(
      {
        id: props.id,
      }
    )
  }, [props.id, provisionWinGetApp]);

  const ProvisionButton = React.useCallback(() => {
    return (
      <Button
        shape="square"
        appearance="subtle"
        aria-label="Provision Application"
        icon={<ArrowSync24Regular />}
        onClick={provisionApplication}
        size="medium"
        disabled={syncTriggered}
      >{(syncTriggered) ? ("Application Provisioned") : ("Provision Application")}</Button>
    )  
  }, [syncTriggered, provisionApplication])

  const ApplicationCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        {
          <CardHeader
            image = {
              (props.largeIcon) && (
                <img src={`data:${JSON.parse(props.largeIcon).type};base64,${JSON.parse(props.largeIcon).value}`} width="150px" height="auto" alt="logo" />
              )
            }
            header={
              <Text>
                <LargeTitle>
                  {props.displayName}
                </LargeTitle>
                <br></br>
                <Body1>
                  {props.vendor}
                </Body1>
              </Text>
            }
          />
        }
        <Body1>
          <br></br>
            <div role="tabpanel" aria-labelledby="item">
              <table>
                <tbody>
                  <tr>
                    <td className={cardStyles.propsTable}>
                      <tr><td>Name</td><td>{props.displayName}</td></tr>
                      <tr><td>Vedor</td><td>{props.vendor}</td></tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </Body1>
        <br></br>
        <Body1 italic align="justify">
          {props.description}
        </Body1>
        <br></br>
        <br></br>
        <table align="center">
          <tbody>
            <tr>
              <td><ProvisionButton /></td>
            </tr>
          </tbody>
        </table>
      </Card>
    );
  },[props, cardStyles, ProvisionButton]);

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <div role="tabpanel" aria-labelledby="item">
            <ApplicationCard />
          </div>
          <div className={applyStyles.content}>
          <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};

export const OpenLogItem = (props: ILogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  // ServiceCard
  const cardStyles = useCardStyles();

  const TopCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        <CardHeader
          // image = {<SubscriptionEnabled />}
          header={
            <Subtitle1>
               {props.DisplayName} [{props.State} | {props.Status}]
            </Subtitle1>
          }
          description={props.DisplayVersion}
        />
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Displayname</td><td>{props.DisplayName}</td></tr>
                  <tr><td>Version</td><td>{props.DisplayVersion}</td></tr>
                  <tr><td>Type</td><td>{props.ApplicationType}</td></tr>
                  <tr><td>State</td><td>{props.State}</td></tr>
                  <tr><td>Status</td><td>{props.Status}</td></tr>
                  <tr><td>Detailed status</td><td>{props.DetailedStatus}</td></tr>
                  <tr><td>Start time</td><td>{props.StartTime}</td></tr>
                  <tr><td>End time</td><td>{props.EndTime}</td></tr>
                  <tr><td>Duration</td><td>{props.Duration}</td></tr>
                  <tr><td>Microsoft EntraID tenantid</td><td>{props.TenantId}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClickCloseButton} //{() => setIsOpen(false)}
              />
            }
          >
            
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <TopCard />
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />} />
    </div>
  );
};