export function SortDate (a:string, b:string) {
    if (typeof a === 'string' && typeof b === 'string') {
      if (!isNaN(Date.parse(a)) || !isNaN(Date.parse(b))) {
        return Date.parse(a).valueOf() - Date.parse(b).valueOf()
      } else if (!isNaN(Date.parse(a)) && isNaN(Date.parse(b))) {
        // a is a date but not b
        return 1;
      } else if (isNaN(Date.parse(a)) && !isNaN(Date.parse(b))) {
        // b is a date but not a
        return -1;
      } else {
        return a.localeCompare(b)
      }
    } else {
      if (a === b) {
        return 0;
      } else if ((a === undefined) || (a === null && b !== undefined)) { 
        // null and undefined should always be swapped to become at the beginning, but undefined should be before null
        return -1;
      } else if (b === undefined || b === null) { 
        return 1; 
      } else return (a < b ? -1 : 1)
    }
}

export function SortString(a:string, b:string) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  } else {
    if (a === b) {
      return 0;
    } else if ((a === undefined) || (a === null && b !== undefined)) {
      return -1;
    } else if (b === undefined || b === null) { 
      return 1; 
    } else return (a < b ? -1 : 1)
  }
}

export function SortBoolean (a:boolean, b:boolean) {
	if (typeof a === 'boolean' && typeof b === 'boolean') {
		return (a === b)? 0 : a? -1 : 1;
	} else {
		if (a === b) {
			return 0;
		} else if ((a === undefined) || (a === null && b !== undefined)) {
			return -1;
		} else if (b === undefined || b === null) { 
			return 1; 
		} else return (a < b ? -1 : 1)
	}
}

export function SortNumber (a:number, b:number) {
	if (typeof a === 'number' && typeof b === 'number') {
		return (a === b)? 0 : a-b;
	} else {
		if (a === b) {
			return 0;
		} else if ((a === undefined) || (a === null && b !== undefined)) {
			return -1;
		} else if (b === undefined || b === null) { 
			return 1; 
		} else return (a < b ? -1 : 1)
	}
}
