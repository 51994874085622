import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Caption1,
} from "@fluentui/react-components";
import { 
    Switch,
    Checkbox,
    CheckboxProps,
  } from "@fluentui/react-components";
import { 
    Edit24Regular,
    InfoRegular,
  } from "@fluentui/react-icons";
import {ISigninLogItem, ISubscriptionLogItem} from '../../Context/ServiceContext'
import {useClientprincipal, IServiceManagementItem} from '../../Context/ClientprincipalContext'

export const OpenService = (props: IServiceManagementItem) => {
    const [active, setActive] = React.useState<boolean>(props['isActive']);
    const [autorenew, setAutorenew] = React.useState<boolean>(props['autorenew']);
    const [confirmationChecked, setConfirmationChecked] = React.useState<CheckboxProps["checked"]>((!props['isActive'])?(false):(true));
    const {mergeServiceSubscriptionStatus} = useClientprincipal();
    const checkBoxLabel = `By confirming, you accept that yor organization is billed ${props.retailprice} ${props.currencycode} per ${props.unittype} for this subscription. The subscription will automatically be renewed when the subscription expire unless automatic renewal is disabled.`
    const renewalMessage = `By enabling automatic renewal of the subscription, you accept that your subscription is automatically renewed when it expires and your organization is billed ${props.retailprice} ${props.currencycode} per ${props.unittype} for this subscription.`

    const onClickApplyButton = React.useCallback(() => {
      console.log(props)
      console.log("Active: " + active)
      console.log("Autorenew: " + autorenew)
      if ( props['isActive'] !== active || props['autorenew'] !== autorenew ) {
        // Update Table
        const inputObject = {
            id: props['id'],
            isActive: active,
            autorenew: autorenew,
            name: props['name'],
        }
        mergeServiceSubscriptionStatus(inputObject);
      }
    }, [props, active, autorenew, mergeServiceSubscriptionStatus]);

    const Active = React.useCallback(() => (
      (!props['isActive']) ? ( 
        <>
          <Switch label={"Activate Subscription"} checked={active} onChange={() => setActive(!active)}  />
          <Checkbox checked={confirmationChecked} onChange={(ev, data) => setConfirmationChecked(data.checked)} label={checkBoxLabel} />
          <br></br>
        </>
      ) : (
        <>
          <Switch label={"Activate Subscription"} checked={active} onChange={() => setActive(!active)} disabled />
          <br></br>
        </>
      )
    ),[props,active,confirmationChecked, checkBoxLabel]);

    // Set autorenew to match active when the value of active is changes
    React.useEffect(() => {
      if((props['isActive'] !== active) && active ) {
        setAutorenew(true)
      }
      if(!active && !props['autorenew']) {
        setAutorenew(false)
      }
    },[props,active]);

    const Autorenew = React.useCallback(() => (
      (active && confirmationChecked) ? ( 
        <>
          <Switch label={"Automatic renewal"} checked={autorenew} onChange={() => setAutorenew(!autorenew)} />
          <br></br>
          {renewalMessage}
          {
            (props['isActive'] && props['expire']) && (
              (props['autorenew']) ? (
                " Subscription renewal occurs at " + (new Date(Date.parse(props['expire']))).toLocaleDateString('sv-SE') + "."
              ) : (
                ((new Date()) < (new Date(Date.parse(props['expire'])))) ? (
                  "The subscription expires at " + (new Date(Date.parse(props['expire']))).toLocaleDateString('sv-SE') + "."
                ) : (
                  "The subscription expired at " + (new Date(Date.parse(props['expire']))).toLocaleDateString('sv-SE') + "."
                )
              )
            )
          }
          <br></br>
        </>
      ) : ( 
        <Switch label={"Automatic renewal"} checked={autorenew} onChange={() => setAutorenew(!autorenew)} disabled/>
      )
    ),[props,autorenew,active,confirmationChecked, renewalMessage]);

  return (
    <Dialog modalType="alert">
      <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<Edit24Regular />} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {props.displayname}
            <br></br>
            <Caption1>
                {`${props?.retailprice} ${props?.currencycode} per ${props?.unittype}`}
            </Caption1>
          </DialogTitle>
          <DialogContent>
              {props.description}
              <br></br>
              <br></br>
              <Active />
              <br></br>
              <Autorenew />
              <br></br>
              <br></br>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button shape="square" appearance="outline">Close</Button>
            </DialogTrigger>
                {
                    ( (props['isActive'] !== active && confirmationChecked) || props['autorenew'] !== autorenew) ? (
                        <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
                    ) :(
                        <Button shape="square" appearance="outline" disabled >Apply</Button>
                    )
                }
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export const OpenSigninlog = (props: ISigninLogItem) => {
return (
  <Dialog>
    <DialogTrigger disableButtonEnhancement>
        <Button appearance="transparent" icon={<InfoRegular />} />
    </DialogTrigger>
    <DialogSurface>
      <DialogBody>
        <DialogTitle>
          {props.userprincipalName}
          <br></br>
          <Caption1>
              Sign-in log details
          </Caption1>
        </DialogTitle>
        <DialogContent>
          <br></br>
          <table>
            <tr><th>Userprincipalname</th><td>{props.userprincipalName}</td></tr>
            <tr><th>Username</th><td>{props.userName}</td></tr>
            <tr><th>Timestamp</th><td>{props.logTimestamp}</td></tr>
            <tr><th>Methods</th><td>{props.amr}</td></tr>
            <tr><th>Tenant Id</th><td>{props.tenantId}</td></tr>
            <tr><th>User Id</th><td>{props.userId}</td></tr>
            <tr><th>Roles</th><td>{props.roles}</td></tr>
          </table>
          <br></br>
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button shape="square" appearance="outline">Close</Button>
          </DialogTrigger>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
);
};

export const OpenSubscriptionlogItem = (props: ISubscriptionLogItem) => {
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<InfoRegular />} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {props.username}
            <br></br>
            <Caption1>
                Subscription log details
            </Caption1>
          </DialogTitle>
          <DialogContent>
            <br></br>
            <table>
              <tr><th>Username</th><td>{props.username}</td></tr>
              <tr><th>Timestamp</th><td>{props.logTimestamp}</td></tr>
              <tr><th>Tenant Id</th><td>{props.tenantid}</td></tr>
              <tr><th>Service Id</th><td>{props.serviceid}</td></tr>
              <tr><th>User Id</th><td>{props.userid}</td></tr>
              <tr><th>Action</th><td>{props.action}</td></tr>
            </table>
            <br></br>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button shape="square" appearance="outline">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
  };