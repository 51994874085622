import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer, IServiceManagementItem } from "./ClientprincipalContext";

export interface IAssetItem {
  id: string;
  deviceName: string;
  autopilotEnrolled: boolean;
  deviceRegistrationState: string;
  deviceStatus: string;
  deviceType: string;
  enrolledDateTime: string;
  firstEnrolledDateTime: string;
  lastAssignedUser: string;
  locationId: string;
  notes: string;
  manufacturer: string;
  model: string;
  operatingSystem: string;
  productName: string;
  serialNumber: string;
  userDisplayName: string;
  userPrincipalName: string;
  warrantyStatus: string;
  warrantyStart: string;
  warrantyEnd: string;
  assignedTo: string;
  assignedBy: string;
  assignmentDate: string;
  groupTag: string;
  managedDeviceId: string;
  azureActiveDirectoryDeviceId: string;
  deploymentProfileAssignmentStatus: string;
}

export interface ILocationItem {
  id: string;
  locationName: string;
}

export interface ILocationItemAdd {
  locationName: string;
}

export interface ILocationItemDelete {
  id: string;
}

export type AssetContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: IServiceManagementItem | undefined;
  assets: IAssetItem[];
  locations: ILocationItem[];
  isLoadingAssets: boolean;
  isLoadingLocations: boolean;
  refreshAssets: () => void;
  mergeAsset: (props: IAssetItem) => void;
  refreshLocations: () => void;
  putLocation: (props: Partial<ILocationItem>) => void;
  deleteLocation: (props: Partial<ILocationItem>) => void;
};

const AssetDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  assets: [],
  locations: [],
  isLoadingAssets: false,
  isLoadingLocations: false,
  refreshAssets: () => {},
  mergeAsset: (props: IAssetItem) => {},
  refreshLocations: () => {},
  putLocation: (props: Partial<ILocationItem>) => {},
  deleteLocation: (props: Partial<ILocationItem>) => {},
};

export interface IProviderProps {
children?: any;
}

const AssetContext = React.createContext<AssetContextType>(AssetDefaultValue)

export const useAssetContext = () => {
  const context = React.useContext(AssetContext);
  if (!context) {
    throw new Error(
      `useAssetContext must be used within a AssetProvider`
    )
  }
  return context;
}

export const AssetProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [assets, setAssets] = React.useState<IAssetItem[]>([]);
  const [locations, setLocations] = React.useState<ILocationItem[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = React.useState<boolean>(false)
  const [isLoadingLocations, setIsLoadingLocations] = React.useState<boolean>(false)
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);

  const refreshAssets = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
      
      setIsLoadingAssets(true);
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/asset/asset', requestOptions)
      .then((response) => response.json())
      .then((result) => setAssets(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingAssets(false);
      })
    } else {
      setAssets([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const mergeAsset = React.useCallback((props: IAssetItem) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      const requestOptions = {
        method: 'MERGE',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
        body: JSON.stringify({
          id: props.id,
          deviceStatus: props.deviceStatus,
          locationId: props.locationId,
          notes: props.notes,
          assignedTo: props.assignedTo
        }),
      };
      fetch('/api/asset/asset', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshAssets();
      })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const refreshLocations = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
  
      setIsLoadingLocations(true);
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => response.json())
      .then((result) => setLocations(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingLocations(false);
      })
    } else {
      setLocations([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const putLocation =  React.useCallback((props: Partial<ILocationItem>) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
          
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
        body: JSON.stringify(props),
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshLocations();
      })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const deleteLocation = React.useCallback((props: Partial<ILocationItem>) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
          
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
        body: JSON.stringify(props),
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshLocations();
      })

    } 
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  // Init asset array
  React.useEffect(() => {
    refreshAssets();
  },[clientprincipal, refreshAssets]);

  // init locations array
  React.useEffect(() => {
    refreshLocations();
  },[clientprincipal, refreshLocations]);

  // Set serviceOffer
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "asset";
      }))
  },[serviceOffers, setServiceOffer]);

  // Set subscription
  React.useEffect(() => {
    setSubscription(
      serviceSubscriptions.find((element: IServiceManagementItem) => {
        return element.name === "asset";
      })
    )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({serviceOffer, subscription, assets, locations, isLoadingAssets, isLoadingLocations, refreshAssets, mergeAsset, refreshLocations, putLocation, deleteLocation}), [serviceOffer, subscription, assets, locations, isLoadingAssets, isLoadingLocations, refreshAssets, mergeAsset, refreshLocations, putLocation, deleteLocation])
  return <AssetContext.Provider value={value} {...props} />
}


