import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
  } from "@fluentui/react-components";
import * as React from "react";
import { 
  Button,
  Text,
  Body1,
  Card,
  CardFooter,
  SpinButton,
  SpinButtonProps,
  useId,
  makeStyles,
  Label,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { 
  Dismiss24Regular,
  Edit24Regular,
  BookInformation24Regular,
  PresenceAvailable24Regular,
  PresenceBlocked24Regular
} from "@fluentui/react-icons";
import {
  Dropdown,
  Option,
} from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import { useMSCSPContext, ICspSubscriptionItem, IGroupItem, ILogItem} from '../../Context/MSCSPContext'
  
const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      ...shorthands.padding("50px", "20px"),
      rowGap: "20px",
    },
    panels: {
      ...shorthands.padding(0, "10px"),
      "& th": {
        textAlign: "left",
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
    propsTable: {
      "& td:first-child": {
        fontWeight: tokens.fontWeightSemibold,
      },
      "& td": {
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
});

const useDropdownStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",

    "> label": {
      marginBottom: tokens.spacingVerticalXXS,
    },
  },
});

const useSpinnerStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100px",

    "> label": {
      marginBottom: tokens.spacingVerticalXXS,
    },
  },
});

// Open Subscription Drawer
/*
export const OpenSubscription = (props: ICspSubscriptionItem) => {
  const {groups, patchManagedSubscription, deleteManagedSubscription} = useMSCSPContext();
  const [offset, setOffset] = React.useState<number>(props.offset || 0);
  const [groupId, setGroupId] = React.useState<string>(props.groupid || '');
  const [isOpen, setIsOpen] = React.useState(false);
  const styles = useStyles();
  const applyStyles = useApplyButtonStyles();
  const [spinButtonValue, setSpinButtonValue] = React.useState<number | null>(props.offset || 0);
  const layoutStyles = useSpinnerStyles();
  const offsetId = useId();

  const HeaderTitle = React.useCallback(() => {
    return (
        (props.groupid.length > 0) ? (
          <>
            <PresenceAvailable24Regular color="Green"/> Managed Subscription
          </>
        ) : (
          <>
            <PresenceBlocked24Regular color="Red"/> Unmanaged Subscription
          </>
        )
    )
  },[props])

  
  const onClickApplyButton = React.useCallback(() => {
    console.log('trigger patchManagedSubscription')
    console.log(props)
    setIsOpen(false);
    if ((offset !== props.offset) || 
        (groupId !== props.groupid)) {
      patchManagedSubscription({
        ...props,
        groupid: groupId,
        offset: offset,
      })
    }
  },[props,offset,groupId, patchManagedSubscription])

  const ApplyCancelButton = React.useCallback(() => {
    if ((offset !== props.offset) || 
        (groupId !== props.groupid)) {
        return (
          <>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Cancel</Button>
          </>
        )
      } else {
        return (
          <>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton} disabled>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton} disabled>Cancel</Button>
          </>
        )
      }
  },[props, offset, groupId, onClickApplyButton])

  const CloseButton = () => {
    return (
      <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
    )
  }

  const onClickCloseButton = () => {
    setIsOpen(false);
  }

  const onClickRemoveButton = React.useCallback(() => {
    console.log('trigger deleteManagedSubscription')
    console.log(props)
    setIsOpen(false);
    deleteManagedSubscription(props)
  },[props, deleteManagedSubscription])

  const RemoveButton = React.useCallback(() => {
    return (
      <Button shape="square" appearance="outline" disabled={(props.groupid && props.offset) ? (false) : (true)} onClick={onClickRemoveButton}>Remove</Button>
    )
  },[props, onClickRemoveButton])

  const OffsetSpinButton = React.useCallback(() => {
 
    const onSpinButtonChange: SpinButtonProps["onChange"] = (_ev, data) => {
        console.log("onSpinButtonChange", data.value, data.displayValue);
        if (data.value !== undefined && data.value !== null) {
          setSpinButtonValue(data.value);
          setOffset(data.value);
        } else if (data.displayValue !== undefined) {
          const newValue = parseFloat(data.displayValue);
          if (!Number.isNaN(newValue)) {
            setSpinButtonValue(newValue);
            setOffset(newValue);
          } else {
            console.error(`Cannot parse "${data.displayValue}" as a number.`);
          }
        }
      }

    return (
      <div className={layoutStyles.base}>
        <Label htmlFor={offsetId}>Offset</Label>
        <SpinButton
          value={spinButtonValue}
          onChange={onSpinButtonChange}
          id={offsetId}
        />
      </div>
    );
  },[layoutStyles.base, offsetId, spinButtonValue]);

  const GroupDropdown = () => {
    const styles = useDropdownStyles();
    const id = useId();
    const selectedGroup = (Array.isArray(groups) && groups.length > 0) ? (
                                groups.find((element: IGroupItem) => {
                                  return element.id === groupId;
                                }) ?? (null)
                              ) : (null)

    return (
      <div className={styles.base}>
        <Label htmlFor={id}>Group</Label>
        <Dropdown
          aria-labelledby={"Group"}
          placeholder="Select a Group"
          id={id}
          defaultValue={(selectedGroup) ? (selectedGroup?.displayName + " (" + selectedGroup?.groupMemberCount +")") : ("")}
          defaultSelectedOptions={[groupId]}
          onOptionSelect={onOptionSelectGroup}
        >
          {groups.map((group:IGroupItem) => (
            <Option key={group.displayName} value={group.id}>
              {group.displayName +' ('+ group.groupMemberCount + ')'}
            </Option>
          ))}
        </Dropdown>
      </div>
    );
  };

  const onOptionSelectGroup: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setGroupId(data.optionValue ?? "") 
  };

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            <HeaderTitle />
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <Text font="monospace">
            <div role="tabpanel" aria-labelledby="item">
              <table className={styles.propsTable}>
                <tbody>
                  <tr><td>Id</td><td>{props.id}</td></tr>
                  <tr><td>Offer Name</td><td>{props.offerName}</td></tr>
                  <tr><td>Quantity</td><td>{props.quantity}</td></tr>
                  <tr><td>Group Id</td><td>{props.groupid}</td></tr>
                  <tr><td>Group Members</td><td>{props.groupMemberCount}</td></tr>
                  <tr><td>Offset</td><td>{props.offset}</td></tr>
                  <tr><td>Approved by</td><td>{props.approvedby}</td></tr>
                  <tr><td>Approval date</td><td>{props.approvalDateTime}</td></tr>
                  <tr><td>Managed</td><td>{(props.groupid.length > 0) ? ( "Yes" ) : ( "No" )}</td></tr>
                </tbody>  
              </table>
              <br></br>
              <br></br>
              <GroupDropdown />
              <br></br>
              <OffsetSpinButton />
              <br></br>
              <br></br>
              <ApplyCancelButton /><RemoveButton />
            </div>
          </Text>
          <div className={applyStyles.content}>
            <CloseButton />
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="outline" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};
*/

// Open Log
export const OpenLog = (props: ILogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  const styles = useStyles();
  const propertyFilter = [
  'odata.type',
  'odata.id',
  'odata.etag',
  'odata.editLink',
  'PartitionKey',
  'RowKey',
  'Timestamp@odata.type',
  'id@odata.type',
  ]

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Log details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                    {
                      Object.entries(props).map(([key, value]) => !propertyFilter.includes(key) ? (
                        ( value === true || value === false ) ? (
                              <tr><td>{key}</td><td>{value.toString()}</td></tr>
                          ):(
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        ) : (
                          <></>
                        )
                      )
                    }
                  </tbody>  
                </table>
              </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>

      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />} />
    </div>
  );
};

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const OpenMscspSubscription = (props: ICspSubscriptionItem) => {
  const {groups, patchManagedSubscription, deleteManagedSubscription} = useMSCSPContext();
  const [offset, setOffset] = React.useState<number>(props.offset || 0);
  const [groupId, setGroupId] = React.useState<string>(props.groupid || '');
  const [isOpen, setIsOpen] = React.useState(false);
  //const styles = useStyles();
  const applyStyles = useApplyButtonStyles();
  const [spinButtonValue, setSpinButtonValue] = React.useState<number | null>(props.offset || 0);
  const layoutStyles = useSpinnerStyles();
  const offsetId = useId();
  const cardStyles = useCardStyles();
  const styles = useDropdownStyles();
  const id = useId();

  const HeaderTitle = React.useCallback(() => {
    return (
        (props.groupid.length > 0) ? (
          <>
            <PresenceAvailable24Regular color="Green"/> Managed Subscription
          </>
        ) : (
          <>
            <PresenceBlocked24Regular color="Red"/> Unmanaged Subscription
          </>
        )
    )
  },[props])
    
  const onClickApplyButton = React.useCallback(() => {
    console.log('trigger patchManagedSubscription')
    console.log(props)
    setIsOpen(false);
    if ((offset !== props.offset) || 
        (groupId !== props.groupid)) {
      patchManagedSubscription({
        ...props,
        groupid: groupId,
        offset: offset,
      })
    }
  },[props,offset,groupId, patchManagedSubscription])

  const ApplyCancelButton = React.useCallback(() => {
    if ((offset !== props.offset) || 
        (groupId !== props.groupid)) {
        return (
          <>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Cancel</Button>
          </>
        )
      } else {
        return (
          <>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton} disabled>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton} disabled>Cancel</Button>
          </>
        )
      }
  },[props, offset, groupId, onClickApplyButton])

  const onClickCloseButton = () => {
    setIsOpen(false);
  }

  const onClickRemoveButton = React.useCallback(() => {
    console.log('trigger deleteManagedSubscription')
    console.log(props)
    setIsOpen(false);
    deleteManagedSubscription(props)
  },[props, deleteManagedSubscription])

  const RemoveButton = React.useCallback(() => {
    return (
      <Button shape="square" appearance="outline" disabled={(props.groupid && props.offset) ? (false) : (true)} onClick={onClickRemoveButton}>Remove</Button>
    )
  },[props, onClickRemoveButton])

  const OffsetSpinButton = React.useCallback(() => {
 
    const onSpinButtonChange: SpinButtonProps["onChange"] = (_ev, data) => {
        console.log("onSpinButtonChange", data.value, data.displayValue);
        if (data.value !== undefined && data.value !== null) {
          setSpinButtonValue(data.value);
          setOffset(data.value);
        } else if (data.displayValue !== undefined) {
          const newValue = parseFloat(data.displayValue);
          if (!Number.isNaN(newValue)) {
            setSpinButtonValue(newValue);
            setOffset(newValue);
          } else {
            console.error(`Cannot parse "${data.displayValue}" as a number.`);
          }
        }
      }

    return (
      <div className={layoutStyles.base}>
        <Label htmlFor={offsetId}>Offset</Label>
        <SpinButton
          value={spinButtonValue}
          onChange={onSpinButtonChange}
          id={offsetId}
        />
      </div>
    );
  },[layoutStyles.base, offsetId, spinButtonValue]);

  const GroupDropdown = React.useCallback(() => {
    const selectedGroup = (Array.isArray(groups) && groups.length > 0) ? (
                                groups.find((element: IGroupItem) => {
                                  return element.id === groupId;
                                }) ?? (null)
                              ) : (null)

    return (
      <div className={styles.base}>
        <Label htmlFor={id}>Group</Label>
        <Dropdown
          aria-labelledby={"Group"}
          placeholder="Select a Group"
          id={id}
          defaultValue={(selectedGroup) ? (selectedGroup?.displayName + " (" + selectedGroup?.groupMemberCount +")") : ("")}
          defaultSelectedOptions={[groupId]}
          onOptionSelect={onOptionSelectGroup}
        >
          {groups.map((group:IGroupItem) => (
            <Option key={group.displayName} value={group.id}>
              {group.displayName +' ('+ group.groupMemberCount + ')'}
            </Option>
          ))}
        </Dropdown>
      </div>
    );
  },[id, groupId, groups, styles.base]);

  const CalculatedOrderResult = React.useCallback(() => {
    const selectedGroup = (Array.isArray(groups) && groups.length > 0) ? (
      groups.find((element: IGroupItem) => {
        return element.id === groupId;
      }) ?? (null)
    ) : (null)

    return (
      <div className={styles.base}>
        {
          ((offset !== props.offset) || 
          (groupId !== props.groupid)) ? (
            <Body1 italic>
              Estimated subscription quantity is {((selectedGroup?.groupMemberCount || 0) + offset)} ({(selectedGroup?.groupMemberCount || 0 + offset) - props.quantity})
            </Body1>
          ) : (
            <Body1 italic>
              
            </Body1>
          )
      }
      </div>
    );
  },[groupId, groups, styles.base, offset, props]);

  const onOptionSelectGroup: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setGroupId(data.optionValue ?? "") 
  };
  
  const MscspTopCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                <tr><td>Id</td><td>{props.id}</td></tr>
                  <tr><td>Offer Name</td><td>{props.offerName}</td></tr>
                  <tr><td>Quantity</td><td>{props.quantity}</td></tr>
                  <tr><td>Group Id</td><td>{props.groupid}</td></tr>
                  <tr><td>Group Members</td><td>{props.groupMemberCount}</td></tr>
                  <tr><td>Offset</td><td>{props.offset}</td></tr>
                  <tr><td>Approved by</td><td>{props.approvedby}</td></tr>
                  <tr><td>Approval date</td><td>{props.approvalDateTime}</td></tr>
                  <tr><td>Managed</td><td>{(props.groupid.length > 0) ? ( "Yes" ) : ( "No" )}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const MscspManagementCard =  React.useCallback(() => {
    
    return (
      <Card className={cardStyles.card}>
        <Body1>
          <br></br>
          <GroupDropdown />
          <br></br>
          <OffsetSpinButton />
          <br></br>
          <CalculatedOrderResult />
          <br></br>
        </Body1>
        {
        <CardFooter>
          <ApplyCancelButton /><RemoveButton />
        </CardFooter>
        }
      </Card>
    );
  },[cardStyles,CalculatedOrderResult,ApplyCancelButton,GroupDropdown,OffsetSpinButton,RemoveButton]);

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClickCloseButton}
              />
            }
          >
            <HeaderTitle />
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <MscspTopCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <MscspManagementCard />
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};