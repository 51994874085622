import * as React from 'react'
import { teamsLightTheme, teamsDarkTheme, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import type { Theme } from '@fluentui/react-components';

export interface ITheme {
  themeName: string;
  theme: Theme;
}

export type ThemeContextType = {
  theme: ITheme;
  setTheme: () => void;
};

export interface IProviderProps {
  children?: any;
}

const ThemeDefaultValue = {
  theme: { themeName: 'Teams Dark', theme: teamsDarkTheme },
  setTheme: (theme: ITheme) => {} // noop default callback
};

const ThemeContext = React.createContext(ThemeDefaultValue)

export const themes = [
  {themeName: 'Teams Dark', theme: teamsDarkTheme},
  {themeName: 'Teams Light', theme: teamsLightTheme},
  {themeName: 'Web Dark', theme: webDarkTheme},
  {themeName: 'Web Light', theme: webLightTheme},
]

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error(
      `useTheme must be used within a ThemeProvider`
    )
  }
  return context;
}

export const ThemeProvider = (props: IProviderProps) => {
  const [theme, setTheme] = React.useState(ThemeDefaultValue.theme);
  const value = React.useMemo(() => ({theme, setTheme}), [theme])

  return <ThemeContext.Provider value={value} {...props} />
};
