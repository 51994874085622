import React from 'react'
import { useClientprincipal } from '../../Context/ClientprincipalContext'

const Profile = () => {
  const {clientprincipal} = useClientprincipal()
  return (
    <div>
      <h3>Profile Data</h3>
      {!clientprincipal?.isAuthenticated ? (
        <div>
          <h2>Authenticated</h2>
          {clientprincipal?.isAuthenticated.toString()}
          <h2>NO user info retrived.</h2>
        </div>
      ) : (
        <div>
          <h2>Authenticated</h2>
          {clientprincipal?.isAuthenticated.toString()}
          <h2>Username</h2>
          {clientprincipal?.userName}
          <h2>Displayname</h2>
          {clientprincipal?.displayName}
          <h2>User Roles</h2>
          {clientprincipal?.roles?.map((role, index) => (
            <li key={index}>{role}</li>
          ))}
          {}
          <h2>User Id</h2>
          {clientprincipal.userId}
          <h2>Tenant Id</h2>
          {clientprincipal.tenantId}
          <h2>Claims</h2>
          {
            clientprincipal.claims?.map((claim, index) => (
              (['roles','aud','iss','exp','name','preferred_username'].includes(claim.typ)) && (
                <li key={index}>{JSON.stringify(claim)}</li>
              )
            ))
          }
        </div>        
      )}
    </div>
  )
}

export default Profile