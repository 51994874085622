import * as React from "react";
import {
  //EditRegular,
  Filter24Regular,
  PresenceAvailable24Regular,
  PresenceBlocked24Regular,
  ArrowSync24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
} from "@fluentui/react-components";
import { 
  makeStyles,
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  DrawerProps,
  SearchBox,
} from "@fluentui/react-components";
import type { 
  SearchBoxProps
} from "@fluentui/react-components";
import { Button, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Checkbox } from "@fluentui/react-components";
import { TextColumnThree24Regular } from "@fluentui/react-icons";
import { Field } from "@fluentui/react-components";
import { useRadiusContext } from "../../Context/RadiusContext";
import type { IRadiusLogItem as IGridItem} from "../../Context/RadiusContext";
import {OpenRadiusItem as OpenItem} from './Drawer'
import {SortString, SortDate, SortBoolean} from "../../Functions/sorting";

const defaultColumns = ['actions','LogDateTime','Response','DeviceSerialNumber','DevicePresent','DeviceComplianceState','CalledStationSSID','DeviceOperatingSystem'] //Default menu
const searchPropertyName = 'DeviceSerialNumber'
const sortAscending = false
const sortColumn = "logDateTime"

export const Placeholder = () => {  
  return (
      <SearchBox placeholder="Search" />
  );
};

export const Controlled = () => {
  const [value, setValue] = React.useState("initial value");
  const [valid, setValid] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setValue(data.value);
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <Field
      label="Controlled SearchBox limiting the value to 20 characters"
      validationState={valid ? "none" : "warning"}
      validationMessage={valid ? "" : "Input is limited to 20 characters."}
    >
      <SearchBox value={value} onChange={onSearchBoxChange} />
    </Field>
  );
};

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
          <OpenItem {...item} />
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "AuthServer",
    compare: (a, b) => {
      return SortString(a.AuthServer,b.AuthServer)
    },
    renderHeaderCell: () => {
      return "Auth Server";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.AuthServer}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "LogDateTime",
    compare: (a, b) => {
      return SortDate(a.LogDateTime,b.LogDateTime)
      //return Date.parse(a.LogDateTime).valueOf() - Date.parse(b.LogDateTime).valueOf()
    },
    renderHeaderCell: () => {
      return "Timestamp";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.LogDateTime }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "Response",
    compare: (a, b) => {
      return SortString(a.Response,b.Response)
    },
    renderHeaderCell: () => {
      return "Response";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.Response === "Access-Accept" ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DeviceSerialNumber",
    compare: (a, b) => {
      return SortString(a.DeviceSerialNumber,b.DeviceSerialNumber)
    },
    renderHeaderCell: () => {
      return "Device Serialnumber";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceSerialNumber}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DevicePresent",
    compare: (a, b) => {
      return SortBoolean(a.DevicePresent,b.DevicePresent)
    },
    renderHeaderCell: () => {
      return "Device Present";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {(item.DevicePresent) ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DeviceComplianceState",
    compare: (a, b) => {
      return SortString(a.DeviceComplianceState,b.DeviceComplianceState)
    },
    renderHeaderCell: () => {
      return "Device Compliance";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceComplianceState === "compliant" ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "CalledStationSSID",
    compare: (a, b) => {
      return SortString(a.CalledStationSSID,b.CalledStationSSID)
    },
    renderHeaderCell: () => {
      return "SSID";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.CalledStationSSID}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DeviceOperatingSystem",
    compare: (a, b) => {
      return SortString(a.DeviceOperatingSystem,b.DeviceOperatingSystem)
    },
    renderHeaderCell: () => {
      return "Operating System";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceOperatingSystem}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "TlsClientCertIssuer",
    compare: (a, b) => {
      return SortString(a.TlsClientCertIssuer,b.TlsClientCertIssuer)
    },
    renderHeaderCell: () => {
      return "Tls Client Cert Issuer";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.TlsClientCertIssuer}
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  LogDateTime: {
    defaultWidth: 250,
    minWidth: 120,
    idealWidth: 250,
  },
  Response: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DevicePresent: {
      minWidth: 60,
      defaultWidth: 80,
  },
  DeviceComplianceState: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DeviceSerialNumber: {
    minWidth: 80,
    defaultWidth: 100,
  },
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const {items, isLoading, refreshItems} = useRadiusContext();
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns
      .includes(o.columnId.toString())
    )))

  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

    const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        {
          isLoading ? (
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular className="Spinner-icon" />}
              size="medium"
            >Refresh</Button>
          ) : (
            <Button
                shape="square"
                appearance="subtle"
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={refreshItems}
                size="medium"
              >Refresh</Button>
          )
        }
      </div>
    )
  }

  interface IfilterSSID {
    name: string;
    enabled: boolean;
  }

  const [filterSSID, setFilterSSID] = React.useState<IfilterSSID[]>([])

  // SSID Filter
  React.useEffect(() => {
    setFilterSSID(
      [...new Set(items.map((item) => item.CalledStationSSID))]
      .map(o => ({
        name: o.toString(),
        enabled: true
      })) as IfilterSSID[]
    )
  },[items])

    // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter
  React.useEffect(() => {
    if(searchFilter !== "") {
      setFilteredItems(
        items.filter(item => 
          (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
          && 
          (filterSSID.filter(o => (o.enabled)).map(o => (o.name)).includes(item.CalledStationSSID))
         )
        )
    } else if(Array.isArray(filterSSID)) {
      setFilteredItems(
        items.filter(item => 
          (filterSSID.filter(o => (o.enabled)).map(o => (o.name)).includes(item.CalledStationSSID))
        )
      )
    } else {
      setFilteredItems(items)
    }
  },[filterSSID, searchFilter, items])

  
  // SSID Filter Drawer
  const SsidDrawer = () => {
    const [ssidRef, setSsidRef] = React.useState<IfilterSSID[]>(filterSSID)
    
    function handleCheckBox(index:number) {
      const nextCounters = ssidRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setSsidRef(nextCounters);
    }
    
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();

    React.useEffect(() => {
      setSsidRef(ssidRef)
    },[ssidRef])

    const [isOpen, setIsOpen] = React.useState(false);
    const [position, setPosition] =
      React.useState<DrawerProps["position"]>("end");
    
    const onClickApplyButton = React.useCallback(() => {
      setFilterSSID(ssidRef);
      setIsOpen(false);
    }, [ssidRef]);
    
    const onClickRightButton = React.useCallback(() => {
      setPosition("end");
      setIsOpen(true);
    }, []);

    return (
      <div>
        <OverlayDrawer
          position={position}
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              {
                //position === "left" ? "Left" : "Right"
              } Select SSID´s
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
          <Checkbox
          checked={
              ssidRef.every(
                o => o.enabled === true
              )
              ? true
              : ssidRef.every(
                  o => o.enabled === false
                )
              ? false
              : "mixed"
          }
          onChange={(_ev, data) => {
            const nextSsidRef = ssidRef
                .map((o) => {
                  return {
                    name: o.name,
                    enabled: !!data.checked,
                  }
                });

            setSsidRef(
              nextSsidRef
            )
          }}
          label="All SSID´s"
        />
        <br></br>
        { ssidRef.map((o, index) =>
            <div>
            <Checkbox
              key={index}
              checked={o.enabled}
              onChange={() => handleCheckBox(index)}
              label={o.name}
            /><br/>
            </div>
          )
        }
        {
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        }
          </DrawerBody>
        </OverlayDrawer>

        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<Filter24Regular />} onClick={onClickRightButton}>
            SSID
          </Button>
        </div>
      </div>
    );
  };

  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IGridItem>;
    }
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    const handleCheckBox = React.useCallback((index:number) => {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    },[columnsRef])
    
    const onClickApplyButton = React.useCallback(() => {
      setIsOpen(false);
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
    }, [columnsRef]);

    const onClickColumnsButton = React.useCallback(() => {
      setIsOpen(true);
    }, []);

    return (
      <div>
        <OverlayDrawer
          position="end"
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <Checkbox
              checked={
                  columnsRef.every( o => o.enabled === true ) ? true : columnsRef.every( o => o.enabled === false ) ? false : "mixed"
              }
              onChange={(_ev, data) => {
                const nextColumnsRef = columnsRef
                    .map((o) => {
                      return {
                        name: o.name,
                        enabled: !!data.checked,
                        column: o.column
                      }
                    });

                setColumnsRef(
                  nextColumnsRef
                )
              }}
              label="All columns"
            />
            <br></br>
            {
              columnsRef.map((o, index) =>
                <div>
                <Checkbox
                  key={index}
                  checked={o.enabled}
                  onChange={() => handleCheckBox(index)}
                  label={o.name}
                /><br></br>
                </div>
              )
            }
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            </div>
          </DrawerBody>
        </OverlayDrawer>
        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickColumnsButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };

  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: sortColumn, sortDirection: (sortAscending) ? ("ascending") : ("descending")}), []
  );

  // GridArea (isLoading)
  const GridArea = React.useCallback(() => {
    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.Timestamp}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          {
            (!isLoading) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <div className='App-tablearea'>
                <Spinner label="Loading..." labelPosition="after"/>
              </div>
            )
          }
        </DataGrid>
      </div>
    )
  },[filteredItems,showColumns,isLoading,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+items.length+")" : "Input is limited to 20 characters."}
        >
          <tr>
            <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
            <td><RefreshButton /></td>
            <td><ColumnsDrawer /></td>
            <td><SsidDrawer /></td>
          </tr>
        </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;