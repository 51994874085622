import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer, IServiceManagementItem } from "./ClientprincipalContext";

export interface IApplicationItem {
  // Application catalog from table: tblApps
  id: string;
  logoUri: string;
  largeIcon: string; //{value: string, type: string};
  displayName: string;
  vendor: string;
  description: string;
  generalVersion: string;
  previewVersion: string;
  updateVersion: string;
  isKeyRequired: boolean;       // licenseKey is required for application
  
  // Calculated in function
  isKeySet: boolean;            // licenseKey exist in tenant table (calculated if required)
  
  // selfService: boolean;    => removed only used by api not tenant.
  
  // Tenant Settings from tabl: {guid}Apps
  isGeneral: boolean;    // General channel is enabled
  isUpdate: boolean;     // Update channel is enabled
  isPreview: boolean;    // Preview channel is enabled
  isPublic:boolean;     // Standard application or Custom
}

export interface IWinGetApplicationItem {
  id: string;
  largeIcon: string; //{value: string, type: string};
  displayName: string;
  vendor: string;
  description: string;
}

export interface ILogItem {
  // Application catalog from table: tblApps
  id: string;
  ApplicationId: string;
  ApplicationType: string;
  DetailedStatus: string;
  DisplayName: string;
  DisplayVersion: string;
  Duration: string;
  EndTime: string;
  StartTime: string;
  State: string;
  Status: string;
  TenantId: string;
}

export type ApplicationContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: IServiceManagementItem | undefined;
  items: IApplicationItem[];
  winGetAppItems: IWinGetApplicationItem[],
  isLoading: boolean;
  logItems: ILogItem[];
  isLogLoading: boolean;
  isWinGetAppItemsLoading: boolean;
  refreshItems: () => void;
  refreshWinGetAppItems: () => void;
  refreshLogItems: () => void;
  synchronizeApplication: (props: Partial<IApplicationItem>) => void;
  provisionWinGetApp: (props: Partial<IWinGetApplicationItem>) => void;
  setApplication: (props: Partial<IApplicationItem>) => void;
};

const ApplicationDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  items: [],
  winGetAppItems: [],
  isLoading: false,
  logItems: [],
  isLogLoading: false,
  isWinGetAppItemsLoading: false,
  refreshItems: () => {},
  refreshWinGetAppItems: () => {},
  refreshLogItems: () => {},
  synchronizeApplication: (props: Partial<IApplicationItem>) => {},
  provisionWinGetApp: (props: Partial<IWinGetApplicationItem>) => {},
  setApplication: (props: Partial<IApplicationItem>) => {},
};

export interface IProviderProps {
children?: any;
}

const ApplicationContext = React.createContext<ApplicationContextType>(ApplicationDefaultValue)

export const useApplicationContext = () => {
  const context = React.useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      `useApplicationContext must be used within a ApplicationProvider`
    )
  }
  return context;
}

export const ApplicationProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const [items, setItems] = React.useState<IApplicationItem[]>([]);
  const [winGetAppItems, setWinGetAppItems] = React.useState<IWinGetApplicationItem[]>([]);
  const [logItems, setLogItems] = React.useState<ILogItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isWinGetAppItemsLoading, setIsWinGetAppItemsLoading] = React.useState<boolean>(false)
  const [isLogLoading, setIsLogLoading] = React.useState<boolean>(false)
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
  
  const refreshItems = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json', 
              'tenantId': clientprincipal.tenantId,
              'userprincipalname': clientprincipal.userName,
              'uid': clientprincipal.userId,
              'displayname': clientprincipal.displayName,
            },
        };
        fetch('/api/application/catalog', requestOptions)
        .then((response) => response.json())
        .then((result) => setItems(result))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
            setIsLoading(false);
        })
      } else {
        setItems([])
      }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const synchronizeApplication = React.useCallback((props: Partial<IApplicationItem>) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      // Syncronize Application (General).
      if (props.isGeneral) {
        console.log(`Syncronizing: ${props.id} (General)`)
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "General",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }

      // Syncronize Application (Update)
      if (props.isUpdate) {
        console.log(`Syncronizing: ${props.id} (Update)`)
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "Update",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }

      // Syncronize Application (Preview)
      if (props.isPreview) {
        console.log(`Syncronizing: ${props.id} (Preview)`)
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "Preview",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const setApplication = React.useCallback((props: Partial<IApplicationItem>) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      // Set Application
        const requestOptions = {
          method: 'MERGE',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
          body: JSON.stringify({
            id: props.id,
            isGeneral: props.isGeneral,
            isUpdate: props.isUpdate,
            isPreview: props.isPreview,
          }),
        };
        fetch('/api/application/catalog', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const refreshWinGetAppItems = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      setIsWinGetAppItemsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/application/wingetapp/catalog', requestOptions)
      .then((response) => response.json())
      .then((result) => setWinGetAppItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsWinGetAppItemsLoading(false);
      })
    } else {
      setItems([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const provisionWinGetApp = React.useCallback((props: Partial<IWinGetApplicationItem>) => {
        if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
          && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
          && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
        body: JSON.stringify({
          "x-mm-tenant-id": clientprincipal.tenantId,
          "x-mm-application-id": props.id
        }),
      };
      fetch('/api/application/wingetapp/provision', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const refreshLogItems = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {

      setIsLogLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/application/log', requestOptions)
      .then((response) => response.json())
      .then((result) => setLogItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLogLoading(false);
      })
    } else {
      setItems([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);
  
  // fetch items from a backend API
  React.useEffect(() => {
    refreshItems();
  }, [clientprincipal, refreshItems]); 

  // fetch WinGetApp items from a backend API
  React.useEffect(() => {
    refreshWinGetAppItems();
  }, [clientprincipal, refreshWinGetAppItems]); 

  // fetch log items from a backend API
  React.useEffect(() => {
      refreshLogItems();
  }, [clientprincipal, refreshLogItems]); 

  // set serviceOffer
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "application";
      }))
  },[serviceOffers, setServiceOffer]);

  // set subscription
  React.useEffect(() => {
      setSubscription(
        serviceSubscriptions.find((element: IServiceManagementItem) => {
          return element.name === "application";
        })
      )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({serviceOffer, subscription, items, winGetAppItems, isLoading, logItems, isLogLoading, isWinGetAppItemsLoading, refreshItems, refreshWinGetAppItems,  refreshLogItems, setApplication, synchronizeApplication, provisionWinGetApp}), [serviceOffer, subscription, items, winGetAppItems, isLoading, logItems, isLogLoading, isWinGetAppItemsLoading, refreshItems, refreshWinGetAppItems,  refreshLogItems, setApplication, synchronizeApplication, provisionWinGetApp])
  return <ApplicationContext.Provider value={value} {...props} />
}
