import React from "react";
import {
  MenuList,
  MenuItem,
  Button,
  Menu,
  MenuTrigger,
  MenuPopover,
  Avatar,
} from "@fluentui/react-components";
import { useNavigate } from 'react-router-dom';
import {
  PersonRegular,
  PersonAddRegular,
} from "@fluentui/react-icons";
import { useClientprincipal } from '../../Context/ClientprincipalContext';

const UserAvatar = () => {
  const {clientprincipal, userPhoto} = useClientprincipal();

  const UserAvatar = React.useCallback(() => {
    if (clientprincipal?.isAuthenticated) {
      return (
        <Avatar 
          icon={(!userPhoto) ? (<PersonRegular />) : (<img src={`data:image/jpeg;base64,${userPhoto}`} alt="of user" />)}
          name={""} size={48} badge={{status: 'available'}} 
          aria-label="Avatar"
        />
      )
    } else {
      return (
        <Avatar icon={<PersonAddRegular />} name={""} size={48} badge={{status: 'offline'}} aria-label="Avatar" />
      )
    }
  },[userPhoto, clientprincipal]);

  return (
      <>
      <tr>
        <td>
          {(clientprincipal?.isAuthenticated) && (clientprincipal?.displayName)}<br/>
          {(clientprincipal?.isAuthenticated) && (clientprincipal?.userName)}
        </td>
        <td>
          <UserAvatar />
        </td>
      </tr>
      </>
  );
}

export const Default = () => {
  const navigate = useNavigate();
  const {clientprincipal, DisposeClientprincipal} = useClientprincipal();
  return (
  <Menu>
    <MenuTrigger disableButtonEnhancement>
      <Button shape="square" appearance="subtle"><UserAvatar/></Button>
    </MenuTrigger>

    <MenuPopover>
    <MenuList>
        { !clientprincipal?.isAuthenticated ? (
          <MenuItem onClick={() => {window.location.pathname = "/login"}}>
            Login
          </MenuItem>
        ):(
          <>
          <MenuItem onClick={() => navigate("/profile")}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => {window.location.pathname = "/login"}}>
            Refresh
          </MenuItem>
          <MenuItem onClick={() => {
                DisposeClientprincipal()
                window.location.pathname = "/logout";
              }
            }>
            Logout
          </MenuItem>
          </>
          )
        }
      </MenuList>
    </MenuPopover>
  </Menu>
);
}