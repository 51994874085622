import * as React from "react";
import {
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
  Button,
} from "@fluentui/react-components";
import { Link } from "@fluentui/react-components";
import type { LinkProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const ApprovAccess = (props: LinkProps & { as?: "a" }) => (
  <Link href="https://login.microsoftonline.com/common/adminconsent?client_id=d2c381fe-a4cf-4671-84a4-9fd0a3757fab" as="a" target="_blank">
    <Button shape="circular" appearance="outline">Approve Access</Button>
  </Link>
);

const CopyLink = () => (
  <Button shape="circular" appearance="outline" onClick={() => {navigator.clipboard.writeText("https://login.microsoftonline.com/common/adminconsent?client_id=d2c381fe-a4cf-4671-84a4-9fd0a3757fab")}}>
    Copy Link
  </Button>
)

export const Faq = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
        <LargeTitle>Instructions</LargeTitle>
        <Body1 align="justify">Ths guide describes the configuration steps needed to use the Intune Application Management service.</Body1>
        <Title3>1. Approve the MMS: Intune Application Management Enterprise application.</Title3>
        <Body1 align="justify">Allow the Intune Application Management Service access to your EntraId tenant by approving the enterprise application.</Body1>
        <Body1 align="start">
          <ApprovAccess /> <CopyLink />
        </Body1>
        <Title3>2. Subscribe to Applications</Title3>
        <Subtitle1>3.1 Subscribe to Applications</Subtitle1>
        <Body1 align="justify">
          <li>Subscribe to a channel (General, Update, Preview)</li>
        </Body1>
        <Subtitle1>3.2 Subscribe to Applications</Subtitle1>
        <Body1 align="justify">
          <li>Assign the subscribed application to devices or users.</li>
        </Body1>
      </div>
    )
};

export default Faq;