import * as React from "react";
import {
  CompoundButton,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { 
  CertificateRegular,
  AppsRegular,
  PhoneLaptopRegular,
  SettingsRegular,
  PhoneLinkSetupRegular,
  WifiLockRegular,
  SearchShieldRegular,
 } from "@fluentui/react-icons";
 import { useNavigate } from 'react-router-dom';
import { useClientprincipal } from '../../Context/ClientprincipalContext'

const services = [
  {
    id: 'serviceadministrator',
    name: 'Service Management',
    icon: <SettingsRegular />,
    uri: 'services/management',
  },
  {
    id: 'azta',
    name: 'Android Zerotouch Assignment',
    icon: <PhoneLinkSetupRegular />,
    uri: 'azta/management',
  },
  {
    id: 'application',
    name: 'Application Management',
    icon: <AppsRegular />,
    uri: 'applications/management',
  },
  {
    id: 'assessment',
    name: 'Assessment Management',
    icon: <SearchShieldRegular />,
    uri: 'assessment/management',
  },
  {
    id: 'asset',
    name: 'Asset Management',
    icon: <PhoneLaptopRegular />,
    uri: 'asset/management',
  },
  {
    id: 'mscsp',
    name: 'MS CSP Management',
    icon: <CertificateRegular />,
    uri: 'mscsp/management',
  },
  {
    id: 'radius',
    name: 'Radius Management',
    icon: <WifiLockRegular />,
    uri: 'radius/management',
  },
];

const useStyles = makeStyles({
  innerWrapper: {
    columnGap: "15px",
    display: "flex",
  },
  outerWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1px",
  },
  buttonWidth: {
    justifyContent: "left",
    ...shorthands.padding("5px"),
  }
});

export const Navigation = () => {
  const {clientprincipal,serviceSubscriptions} = useClientprincipal()
  const navigate = useNavigate();
  const styles = useStyles();
  const [activeView,setActiveView] = React.useState<string>('');


  const NavigationPane = React.useCallback(() => {

    return (
      <div className={styles.outerWrapper}>
        {
          (clientprincipal?.isAuthenticated && Array.isArray(services)) && (
            services
              .filter(service => clientprincipal?.roles?.includes(service['id']))
              .filter(service => (serviceSubscriptions?.filter(o => (o.isActive))?.map(o => o.name)?.includes(service['id']) || service['id'] === 'serviceadministrator'))
              .map((service,index) => (
                <CompoundButton
                  className={styles.buttonWidth}
                  shape="square"
                  appearance={(service?.id !== activeView) ? ("subtle") : ("outline")}
                  icon={service.icon}
                  size="small"
                  onClick={() => {setActiveView(service.id);navigate(service.uri)}}
                >
                {service.name}
              </CompoundButton>
              ))
          )
        }
      </div>
    )
  },[activeView, clientprincipal, serviceSubscriptions, navigate, styles])
  return (
    <NavigationPane />
  );
}
