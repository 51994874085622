import * as React from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Card,
  CardHeader,
  Button,
  Text,
  Body1,
  makeStyles,
  shorthands,
  tokens,
  Subtitle1,
} from "@fluentui/react-components";
import { 
  Dismiss24Regular,
  BookInformation24Regular,
} from "@fluentui/react-icons";

import {IRadiusLogItem} from '../../Context/RadiusContext'

export const ShowWifiXml = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const drawerContent = `
<?xml version="1.0" encoding="UTF-8"?>
<WLANProfile xmlns="http://www.microsoft.com/networking/WLAN/profile/v1">
  <name>ModernManaged</name>
  <SSIDConfig>
      <SSID>
      <hex>4D6F6465726E4D616E61676564</hex>
      <name>ModernManaged</name>
      </SSID>
      <nonBroadcast>false</nonBroadcast>
  </SSIDConfig>
  <connectionType>ESS</connectionType>
  <connectionMode>auto</connectionMode>
  <autoSwitch>true</autoSwitch>
  <MSM>
      <security>
          <authEncryption>
              <authentication>WPA2</authentication>
              <encryption>AES</encryption>
              <useOneX>true</useOneX>
          </authEncryption>
          <OneX xmlns="http://www.microsoft.com/networking/OneX/v1">
              <authMode>machine</authMode>
              <EAPConfig>
                  <EapHostConfig xmlns="http://www.microsoft.com/provisioning/EapHostConfig">
                      <EapMethod>
                          <Type xmlns="http://www.microsoft.com/provisioning/EapCommon">13</Type>
                          <VendorId xmlns="http://www.microsoft.com/provisioning/EapCommon">0</VendorId>
                          <VendorType xmlns="http://www.microsoft.com/provisioning/EapCommon">0</VendorType>
                          <AuthorId xmlns="http://www.microsoft.com/provisioning/EapCommon">0</AuthorId>
                      </EapMethod>
                      <Config>
                          <Eap xmlns="http://www.microsoft.com/provisioning/BaseEapConnectionPropertiesV1">
                              <Type>13</Type>
                              <EapType xmlns="http://www.microsoft.com/provisioning/EapTlsConnectionPropertiesV1">
                                  <CredentialsSource>
                                      <CertificateStore>
                                          <SimpleCertSelection>true</SimpleCertSelection>
                                      </CertificateStore>
                                  </CredentialsSource>
                                  <ServerValidation>
                                      <DisableUserPromptForServerValidation>false</DisableUserPromptForServerValidation>
                                      <ServerNames />
                                      <TrustedRootCA>5d 47 f3 4e c8 38 d9 a2 72 5d 4a c3 69 45 31 18 4d 64 93 fa</TrustedRootCA>
                                  </ServerValidation>
                                  <DifferentUsername>false</DifferentUsername>
                                  <PerformServerValidation xmlns="http://www.microsoft.com/provisioning/EapTlsConnectionPropertiesV2">true</PerformServerValidation>
                                  <AcceptServerName xmlns="http://www.microsoft.com/provisioning/EapTlsConnectionPropertiesV2">false</AcceptServerName>
                                  <TLSExtensions xmlns="http://www.microsoft.com/provisioning/EapTlsConnectionPropertiesV2">
                                      <FilteringInfo xmlns="http://www.microsoft.com/provisioning/EapTlsConnectionPropertiesV3">
                                          <CAHashList Enabled="true">
                                              <IssuerHash>a2 1b a4 b1 62 0c ec 49 d4 37 85 2d 55 c7 48 c6 ee 8f 05 47</IssuerHash>
                                              <IssuerHash>59 47 06 97 20 10 b9 ca fa 51 12 96 c7 c2 6a 3d fd ed 39 b1</IssuerHash>
                                              <IssuerHash>60 6c 2d fa 9a 08 2a f5 ae 3c 5c 83 ad 5a a0 ab 1e cc ea a0</IssuerHash>
                                              <IssuerHash>9e a7 7b a6 d3 0b b2 ab 2d ec e2 df dc 24 70 42 9d cc 36 77</IssuerHash>
                                              <IssuerHash>60 b0 30 1d 32 ba 3b 20 16 68 34 98 bd 27 1f ab de 78 0b 1a</IssuerHash>
                                          </CAHashList>
                                      </FilteringInfo>
                                  </TLSExtensions>
                              </EapType>
                          </Eap>
                      </Config>
                  </EapHostConfig>
              </EAPConfig>
          </OneX>
      </security>
  </MSM>
</WLANProfile>
`.replaceAll(' ','\u00a0').split("\n").map(row => (<>{row}<br/></>)) // Replace space with unicode space and create a string array.
  
  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            WiFi - WLAN Configuration Profile
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>Example configuration: wlanprofile.xml<br></br>
          {drawerContent}
          {
          //drawerContent.split("\n").map(row => (<>{row}<br/></>))
          }
          </p>
        </DrawerBody>
      </OverlayDrawer>

      <Button appearance="outline" onClick={() => setIsOpen(true)}>
          Show xml
      </Button>
    </div>
  );
};

export const ShowRadsecproxyXml = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const drawerContent = `
tls default {
  CACertificateFile       /etc/radsecproxy/ca.pem
  CertificateFile         /etc/radsecproxy/client.pem
  CertificateKeyFile      /etc/radsecproxy/clientkey.pem
  CertificateKeyPassword  "<YOUR_PASSWORD>"
}

client wifi {
  host 0.0.0.0/0
  type udp
  secret cloudauth
}

#ServerName
server ateafr.yellowmoss-09ca6035.swedencentral.azurecontainerapps.io {
type TLS
  tls default
  statusserver off
  CertificateNameCheck off
  Secret cloudauth
  # ServerName "CN=Modern Managed RADSEC Service"
}
#20.240.36.2

realm * {
  server ateafr.yellowmoss-09ca6035.swedencentral.azurecontainerapps.io
}
`.replaceAll(' ','\u00a0').split("\n").map(row => (<>{row}<br/></>))
  
  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Radsec Proxy Configuration
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>Example configuration: radsecproxy.conf<br></br>
          <Text font="monospace">
              {drawerContent}
              {
              //drawerContent.split("\n").map(row => (<>{row}<br/></>))
              }
          </Text>
          </p>
        </DrawerBody>
      </OverlayDrawer>

      <Button appearance="outline" onClick={() => setIsOpen(true)}>
          show radsecproxy.conf
      </Button>
    </div>
  );
};

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

// Open Application Drawer
export const OpenItem = (props: IRadiusLogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  const styles = useStyles();
  const propertyFilter = [
  'odata.type',
  'odata.id',
  'odata.etag',
  'odata.editLink',
  'PartitionKey',
  'RowKey',
  'Timestamp@odata.type',
  'id@odata.type',
  ]

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Log details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              {
                // drawerContent
              }

              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                    {
                      Object.entries(props).map(([key, value]) => !propertyFilter.includes(key) ? (
                        ( value === true || value === false ) ? (
                              <tr><td>{key}</td><td>{value.toString()}</td></tr>
                          ):(
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        ) : (
                          <></>
                        )
                      )
                    }
                  </tbody>  
                </table>
              </div>
              {
              //drawerContent.split("\n").map(row => (<>{row}<br/></>))
              }
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>

      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />}>
          { 
            // Open
          }
      </Button>
    </div>
  );
};

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const OpenRadiusItem = (props: IRadiusLogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  // ServiceCard
  const cardStyles = useCardStyles();

  const RadiusTopCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        <CardHeader
          // image = {<SubscriptionEnabled />}
          header={<Subtitle1>Radius log summary</Subtitle1>}
          //description={}
        />
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Device name</td><td>{props.DeviceName}</td></tr>
                  <tr><td>User name</td><td>{props.UserName}</td></tr>
                  <tr><td>Called Station SSID</td><td>{props.DeviceComplianceState}</td></tr>
                  <tr><td>Timestamp</td><td>{props.LogDateTime}</td></tr>
                  <tr><td>Response</td><td>{props.Response}</td></tr>
                  <tr><td>Reason</td><td>{props.Reason}</td></tr>
                  <tr><td>Auth Server</td><td>{props.AuthServer}</td></tr>
                  <tr><td>Device in Intune</td><td>{(typeof props?.DevicePresent === 'boolean') && (props.DevicePresent.toString())}</td></tr>
                  <tr><td>Compliance state</td><td>{props.DeviceComplianceState}</td></tr>
                  <tr><td>Microsoft EntraID tenant</td><td>{props.TenantId}</td></tr>
                  <tr><td>ListenTlsClientCertCommonName</td><td>{props.ListenTlsClientCertCommonName}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
        {
        /*
        <CardFooter>
        {(props.compliant === false) && (
          <>
          <br></br>
          <IsAddressed />
          <br></br>
          <IsAccepted />
          <br></br>
          </>
        )}
        </CardFooter>
        */
        }
      </Card>
    );
  },[props,cardStyles]);

  const RadiusTlsDetailsCard =  React.useCallback(() => {
    
    return (
      <Card className={cardStyles.card}>
        <CardHeader
          //image = {}
          header={<Subtitle1>TLS & Certificate Details</Subtitle1>}
          //description={}
        />
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>TLS Client Certificate CommonName</td><td>{props.TlsClientCertCommonName}</td></tr>
                  <tr><td>TLS Client Certificate Issuer</td><td>{props.TlsClientCertIssuer}</td></tr>
                  <tr><td>TLS Client Certificate Oid14</td><td>{props.TlsClientCertOid14}</td></tr>
                  <tr><td>RADSEC TLS Client Cert CommonName</td><td>{props.ListenTlsClientCertCommonName}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
        {
        /*
        <CardFooter>
        {(props.compliant === false) && (
          <>
          <br></br>
          <IsAddressed />
          <br></br>
          <IsAccepted />
          <br></br>
          </>
        )}
        </CardFooter>
        */
        }
      </Card>
    );
  },[props,cardStyles]);

  const RadiusDeviceDetailsCard =  React.useCallback(() => {
    
    return (
      <Card className={cardStyles.card}>
        <CardHeader
          //image = {}
          header={<Subtitle1>Device Details</Subtitle1>}
          //description={}
        />
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Device name</td><td>{props.DeviceName}</td></tr>
                  <tr><td>Serialnumber</td><td>{props.DeviceSerialNumber}</td></tr>
                  <tr><td>Operating system</td><td>{props.DeviceOperatingSystem}</td></tr>
                  <tr><td>Microsoft Intune Device found</td><td>{(typeof props?.DevicePresent === 'boolean') && (props.DevicePresent.toString())}</td></tr>
                  <tr><td>Microsoft Intune Compliance state</td><td>{props.DeviceComplianceState}</td></tr>
                  <tr><td>Radius User name</td><td>{props.UserName}</td></tr>
                  <tr><td>Microsoft Intune device Id</td><td>{props.DeviceId}</td></tr>
                  <tr><td>Microsoft EntraID tenant</td><td>{props.TenantId}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
        {
        /*
        <CardFooter>
        {(props.compliant === false) && (
          <>
          <br></br>
          <IsAddressed />
          <br></br>
          <IsAccepted />
          <br></br>
          </>
        )}
        </CardFooter>
        */
        }
      </Card>
    );
  },[props,cardStyles]);

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClickCloseButton} //{() => setIsOpen(false)}
              />
            }
          >
            Radius Log Details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <RadiusTopCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <RadiusDeviceDetailsCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <RadiusTlsDetailsCard />
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />} />
    </div>
  );
};

export default ShowWifiXml