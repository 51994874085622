import * as React from "react";
import {
  Tab,
  TabList,
  Spinner,
} from "@fluentui/react-components";
import {
  Settings24Filled,
  Settings24Regular,
  Apps24Filled,
  Apps24Regular,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  DatabaseSearch24Filled,
  DatabaseSearch24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as Faq } from './Faq'
import { default as Settings } from './Settings'
import { default as ApplicationManagement } from './ApplicationManagement'
import { default as WinGetAppManagement } from './WinGetAppManagement'
import { default as Logs } from './Logs'
import { useApplicationContext as useContext} from "../../Context/ApplicationContext";

const SettingsIcons = bundleIcon(Settings24Filled, Settings24Regular)
const FaqIcons = bundleIcon(DocumentOnePageMultiple24Filled, DocumentOnePageMultiple24Regular);
const AppsIcons = bundleIcon(Apps24Filled, Apps24Regular);
const LogsIcons = bundleIcon(DatabaseSearch24Filled, DatabaseSearch24Regular);

export const TabsWithPanels = () => {
  const {subscription} = useContext();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>()
  
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const SettingsPanel = React.memo(() => (
      <Settings />
  ));

  const FaqPanel = React.memo(() => (
      <Faq />
  ));

  const ApplicationManagementPanel = React.memo(() => (
      <ApplicationManagement />
  ));

  const WinGetAppManagementPanel = React.memo(() => (
      <WinGetAppManagement />
  ));

  const LogsPanel = React.memo(() => (
      <Logs />
  ));

  const ActiveTabs = React.useCallback(() => {
    const defaultSelectedValue = (subscription?.isEnabled) ? ( 'management' ) : ( 'faq' )
    if (!selectedValue) { setSelectedValue(defaultSelectedValue)}
    
    return (
      <>
      {(subscription?.isEnabled) ? (
        <>
          <TabList defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Settings" icon={<SettingsIcons />} value="settings">
              Settings
            </Tab>
            <Tab id="FAQ" icon={<FaqIcons />} value="faq">
              FAQ
            </Tab>
            <Tab id="Applications" icon={<AppsIcons />} value="management">
              Applications
            </Tab>
            <Tab id="WinGetApps" icon={<AppsIcons />} value="wingetappmanagement">
              Microsoft Store
            </Tab>
            <Tab id="Logs" icon={<LogsIcons />} value="logs">
              Logs
            </Tab>
          </TabList>
          <div className="App-contentgrid">
            {selectedValue === "faq" && <FaqPanel />}
            {selectedValue === "settings" && <SettingsPanel />}
            {selectedValue === "management" && <ApplicationManagementPanel />}
            {selectedValue === "wingetappmanagement" && <WinGetAppManagementPanel />}
            {selectedValue === "logs" && <LogsPanel />}
          </div>
        </>
      ) : (
        <>
          <TabList defaultSelectedValue={defaultSelectedValue} selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Settings" icon={<SettingsIcons />} value="settings">
              Settings
            </Tab>
            <Tab id="FAQ" icon={<FaqIcons />} value="faq">
              FAQ
            </Tab>
          </TabList>
          <div className="App-contentgrid">
            {selectedValue === "faq" && <FaqPanel />}
            {selectedValue === "settings" && <SettingsPanel />}
          </div>
        </>
      )}
      </>
    )
  },[subscription, selectedValue, ApplicationManagementPanel, FaqPanel, LogsPanel, SettingsPanel, WinGetAppManagementPanel])

  return (
    (!subscription) ? (
      <Spinner size="large" label="Fetching subscription..." labelPosition="below"/>
    ) : (
      <ActiveTabs />
    )
  );
};

export default TabsWithPanels;