import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer, IServiceManagementItem } from "./ClientprincipalContext";

export interface ISettingsObject {
  zerotouchCompanyId: string | undefined;
  zerotouchCompanyName: string | undefined;
  notificationEmail: string | undefined;
}

export interface ILogItem {
    "Timestamp": string;
    "assignedMetadata": string;
    "deviceIdentifier": string;
    "logDateTime": string;
    "newConfiguration": string;
    "previousConfiguration": string;
    "status": string;
}

export interface ISettingsValidationStateObject{
  tenantId: string;
  zeroTouchCompanyId: string;
  zeroTouchCompanyName: string;
  customerAccess: boolean;
  resellerInvite: boolean;
}

export type AztaContextType = {
  serviceOffer: IServiceOffer | undefined;
  settings: ISettingsObject | undefined,
  items: ILogItem[];
  isLoading: boolean;
  subscription: IServiceManagementItem | undefined;
  validationState: ISettingsValidationStateObject | undefined;
  refreshItems: () => void;
  getSettings: () => void;
  mergeSettings: (props: Partial<ISettingsObject>) => void;
  validateSettings: (props: Partial<ISettingsObject>) => void;
};

export interface IProviderProps {
  children?: any;
}

export const DefaultISettingsObject = {
  zeroTouchCompanyId: undefined,
  zeroTouchCompanyName: undefined,
  notificationEmail: undefined,
};

const AztaDefaultValue = {
  serviceOffer: undefined,
  settings: undefined,
  subscription: undefined,
  items: [],
  isLoading: false,
  validationState: undefined,
  refreshItems: () => {},
  getSettings: () => {},
  mergeSettings: (props: Partial<ISettingsObject>) => {},
  validateSettings: (props: Partial<ISettingsObject>) => {},
};

const AztaContext = React.createContext<AztaContextType>(AztaDefaultValue)

export const useAztaContext = () => {
  const context = React.useContext(AztaContext);
  if (!context) {
    throw new Error(
      `useAztaContext must be used within a AztaProvider`
    )
  }
  return context;
}

export const AztaProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [items, setItems] = React.useState<ILogItem[]>([]);
  const [isLoading, setIsLoading] = React.useState(false)
  const [validationState, setValidationState] = React.useState<ISettingsValidationStateObject>();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
  const [settings, setSettings] = React.useState<ISettingsObject | undefined>(undefined)

  const getSettings = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){

      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/azta/settings', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISettingsObject) : (undefined))))
      .then((result) => {setSettings(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const mergeSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){

      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          zerotouchCompanyId: props.zerotouchCompanyId,
          zerotouchCompanyName:props.zerotouchCompanyName,
          notificationEmail:props.notificationEmail
        }),
      };
      fetch('/api/azta/settings', requestOptions)
        .then((response) => (response.status === 204) ? (
          getSettings()
        ) : (
          console.log('Failed to set aztaSettings.') 
        ))
        .catch((error) => console.log("An error occured" + error))
        .finally(() => {
      })
    }
  },[settings, getSettings, clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName])
  
  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      
        // Get Items
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/azta/logs', requestOptions)
        .then((response) => response.json())
        .then((result) => setItems(result))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
            setIsLoading(false);
      })
      
    } else {
      setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName])

  const validateSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){

      setIsLoading(true);
      // Validate Subscription
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
        body: JSON.stringify({
          tenantId: clientprincipal.tenantId,
          zeroTouchCompanyId: props.zerotouchCompanyId,
          zeroTouchCompanyName: props.zerotouchCompanyName,
        }),
      };
      fetch('/api/azta/subscription/validate', requestOptions)
      .then((response) => response.json())
      .then((result) => {setValidationState(result);console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoading(false);
      })

    } else {
      // alert("isAuthenticated: " + clientprincipal?.isAuthenticated + " :: Azta roles: " + clientprincipal?.roles?.includes('azta') + " :: tenantId: " + clientprincipal.tenantId)
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName])

  // Init items
  React.useEffect(() => {
    refreshItems();
  }, [clientprincipal,subscription, validationState, refreshItems]);

  // Validate settings
  React.useEffect(() => {
    (settings?.zerotouchCompanyId && settings?.zerotouchCompanyName) && (
      validateSettings(
        {
          zerotouchCompanyId: settings?.zerotouchCompanyId,
          zerotouchCompanyName: settings?.zerotouchCompanyName,
        }
      )
    )
  }, [settings, clientprincipal, validateSettings]);

  // Get settings
  React.useEffect(() => {
    getSettings();
  }, [clientprincipal, getSettings]);

  // Set serviceOffers
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "azta";
      }))
  },[serviceOffers, setServiceOffer]);

  // Set subscription
  React.useEffect(() => {
    setSubscription(
      serviceSubscriptions.find((element: IServiceManagementItem) => {
        return element.name === "azta";
      })
    )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({settings, serviceOffer, items, isLoading, subscription, validationState, refreshItems,  getSettings, mergeSettings, validateSettings}), [settings, serviceOffer, items, isLoading, subscription, validationState, refreshItems, getSettings, mergeSettings, validateSettings])
  return <AztaContext.Provider value={value} {...props} />
}
