import * as React from "react";
import { ServiceCard } from "./ServiceCard";

export const Settings = () => {
  return (
    <div>
      <ServiceCard />
    </div>
  )
};

export default Settings;
