import React from 'react';
import { Default as ThemeMenu } from '../Theme/Menu';
import { Default as AvatarMenu } from '../Avatar/Menu';
import { makeStyles, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "right",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

function HeaderPane() {
  const styles = useStyles();
     
  return (
    <div className={styles.content}>  
      <ThemeMenu /> 
      <AvatarMenu />
    </div>
  );
}
export default HeaderPane;