import * as React from "react";
import {
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

export const Faq = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
        <LargeTitle>AZT Configuration Assignment</LargeTitle>
        <Body1 align="justify">All the steps below must be completed before this service can assign configurations based on order metadata.</Body1>
        <Title3>Android Zero Touch Portal</Title3>
        <Body1 align="justify">
          Link to the Android Zero Touch Portal: https://partner.android.com/zerotouch<br/>
          <br/>
          Atea must be invited to the your Android Zero Touch Portal. The Google account to add is zerotouch@modernmanaged.services and need to be assigned the role admin.<br/>
          <br/>
          You can do this by log in to the Android Zero Touch portal and then in the menu select users. On the top right at the page click on the + icon to add user.<br/>
        </Body1>
        <Subtitle1>Zero Touch Company Name</Subtitle1>
        <Body1 italic> Example: [Company Name] (e.g. Contoso)</Body1>
        <Body1 align="justify">
          You can find your company name in the Android Zero Touch Portal on the top left at the first page when you log in.<br/>
        </Body1>
        <Subtitle1>Zero Touch Customer ID</Subtitle1>
        <Body1 italic>Example: [0000000000] (e.g. 1234567890)</Body1>
        <Body1 align="justify">
          You can find your customer id in the Android Zero Touch Portal if you in the menu select resellers and then at the top off the page you should have Your customer ID<br/>
          <br/>
          If the service cannot access your Android Zero Touch with the above Google account or if the Zero Touch Company Name is not matching the Zero Touch Customer ID the service cannot be enabled.<br/>
          <br/>
          Please check that all prerequisites are met before you try to provision the service.<br/>
        </Body1>   
        <Subtitle1>Technical Contact Email</Subtitle1>
        <Body1 align="justify">
          This technical contact will be notified when the service is set up and the customer can start to order devices with this registration service. Atea will also try to contact this person if there is some problem with the service.<br/>
          <br/>
          Service Information Contact Email<br/>
          The service will send an email to this address when a device is assigned to a new configuration or an assignment error has occurred. For example when no configuration matched the assigned configuration name from the order.<br/>
        </Body1>
        <Title3>For Atea eShop:</Title3>
        <Body1 align="justify">
          Contact your account manager at Atea to get help to set up the required registration service so you can choose what Android Zero Touch Configuration you want the device to be assigned after Atea has register the device to Android Zero Touch.<br/>
        </Body1>
      </div>
    )
};

export default Faq;
