import * as React from "react";
import {
  Dismiss24Regular,
  //PresenceAvailable24Regular,
  //PresenceBlocked24Regular,
  ArrowSync24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
} from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import { Button, tokens } from "@fluentui/react-components";
import { Checkbox } from "@fluentui/react-components";
import { TextColumnThree24Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-components";
import type { SearchBoxProps } from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import {OpenSigninlog as OpenItem} from './Dialog'
import {
  useServiceContext as useContext,
  ISigninLogItem as IGridItem
} from '../../Context/ServiceContext'
import {SortString, SortDate, SortNumber} from "../../Functions/sorting";

const defaultColumns = ['actions','logTimestamp','userprincipalName','userName','amr','roles'];
const searchPropertyName = 'userprincipalName'
const sortAscending = false
const sortColumn = "logTimestamp"

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
          <OpenItem {...item} />
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "logTimestamp",
    compare: (a, b) => {
      return SortDate(a.logTimestamp,b.logTimestamp)
      //return Date.parse(a.logTimestamp).valueOf() - Date.parse(b.logTimestamp).valueOf()
    },
    renderHeaderCell: () => {
      return "Timestamp";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.logTimestamp}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "userprincipalName",
    compare: (a, b) => {
      return SortString(a.userprincipalName,b.userprincipalName)
    },
    renderHeaderCell: () => {
      return "userprincipalName";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.userprincipalName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "userName",
    compare: (a, b) => {
      return SortString(a.userName,b.userName)
    },
    renderHeaderCell: () => {
      return "Username";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.userName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "amr",
    compare: (a, b) => {
      return SortString(a.amr,b.amr)
    },
    renderHeaderCell: () => {
      return "amr";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.amr}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "roles",
    compare: (a, b) => {
      return SortString(a.roles,b.roles)
    },
    renderHeaderCell: () => {
      return "roles";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.roles}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "userId",
    compare: (a, b) => {
      return SortString(a.userId,b.userId)
    },
    renderHeaderCell: () => {
      return "userId";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.userId}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "tenantId",
    compare: (a, b) => {
      return SortString(a.tenantId,b.tenantId)
    },
    renderHeaderCell: () => {
      return "tenantId";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.tenantId}
        </TableCellLayout>
      );
    },
  }),

];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  logTimestamp: {
    minWidth: 120,
    defaultWidth: 120,
  },
  userprincipalName: {
    minWidth: 80,
    defaultWidth: 240,
  },
  userName: {
    minWidth: 80,
    defaultWidth: 120,
  },
  amr: {
    minWidth: 80,
    defaultWidth: 80,
  },
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const {signinlogItems, isLoadingSigninlogs, getSigninlogItems} = useContext();
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns //Default menu
      .includes(o.columnId.toString())
    )))
  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <>
        {
        isLoadingSigninlogs ? (
          <div className={styles.content}>
          <Button
            shape="square"
            appearance="subtle"
            aria-label="Refresh"
            icon={<ArrowSync24Regular className="Spinner-icon" />}
            size="medium"
          >Refresh</Button>
        </div>
        ) : (
          <div className={styles.content}>
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular />}
              onClick={getSigninlogItems}
              size="medium"
            >Refresh</Button>
          </div>
        )}
      </>
    )
  }

  // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter (displayname)
  React.useEffect(() => {
    if (Array.isArray(signinlogItems)) {
      if(searchFilter !== "") {
        setFilteredItems(
          signinlogItems.filter(item => 
            (
              (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
            )
          )
        )
      } else {
        setFilteredItems(signinlogItems)
      }
    }
  },[searchFilter, signinlogItems, filteredItems])

  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IGridItem>;
    }
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    const handleCheckBox = React.useCallback((index:number) => {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    },[columnsRef])
    
    const onClickApplyButton = React.useCallback(() => {
      setIsOpen(false);
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
    }, [columnsRef]);

    const onClickColumnsButton = React.useCallback(() => {
      setIsOpen(true);
    }, []);

    return (
      <div>
        <OverlayDrawer
          position="end"
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <Checkbox
              checked={
                  columnsRef.every( o => o.enabled === true ) ? true : columnsRef.every( o => o.enabled === false ) ? false : "mixed"
              }
              onChange={(_ev, data) => {
                const nextColumnsRef = columnsRef
                    .map((o) => {
                      return {
                        name: o.name,
                        enabled: !!data.checked,
                        column: o.column
                      }
                    });

                setColumnsRef(
                  nextColumnsRef
                )
              }}
              label="All columns"
            />
            <br></br>
            {
              columnsRef.map((o, index) =>
                <div>
                <Checkbox
                  key={index}
                  checked={o.enabled}
                  onChange={() => handleCheckBox(index)}
                  label={o.name}
                /><br></br>
                </div>
              )
            }
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            </div>
          </DrawerBody>
        </OverlayDrawer>
        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickColumnsButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };

  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: sortColumn, sortDirection: (sortAscending) ? ("ascending") : ("descending")}), []
  );

  // GridArea (isLoadingSigninlogs)
  const GridArea = React.useCallback(() => {
   
    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.Timestamp}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          {
            (!isLoadingSigninlogs) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <div className='App-tablearea'>
                <Spinner label="Loading..." labelPosition="below"/>
              </div>
            )
          }
        </DataGrid>
      </div>
    )
  },[filteredItems,showColumns,isLoadingSigninlogs,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+signinlogItems.length+")" : "Input is limited to 20 characters."}
        >
        <tr>
          <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
          <td><RefreshButton /></td>
          <td><ColumnsDrawer /></td>
        </tr>
        </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;