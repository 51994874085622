import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
    Card,
    CardHeader,
    Caption2,
    Body1,
    Input,
    InputProps,
  } from "@fluentui/react-components";
  import {useRef} from "react";
  import * as React from "react";
  
  import { 
    Button,
    Text,
    tokens,
  } from "@fluentui/react-components";
  import { 
    Dismiss24Regular,
    Edit24Regular,
    Delete24Regular,
    LocationAdd24Regular,
  } from "@fluentui/react-icons";

  import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
  } from "@fluentui/react-components";
  import type { DropdownProps } from "@fluentui/react-components";
  import { Field, Textarea } from "@fluentui/react-components";
  import type { TextareaProps } from "@fluentui/react-components";
  
  import { useAssetContext, IAssetItem, ILocationItem } from '../../Context/AssetContext'
  
  const useApplyButtonStyles = makeStyles({
    content: {
      display: "flex",
      justifyContent: "left",
      position: "absolute",
      marginBottom: '1em',
      bottom: 0,
      columnGap: tokens.spacingHorizontalXS,
    },
  });

  const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      ...shorthands.padding("50px", "20px"),
      rowGap: "20px",
    },
    panels: {
      ...shorthands.padding(0, "10px"),
      "& th": {
        textAlign: "left",
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
    propsTable: {
      "& td:first-child": {
        fontWeight: tokens.fontWeightSemibold,
      },
      "& td": {
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
  });

// Open Asset Drawer
/*
export const OpenAsset = (props: IAssetItem) => {
  const {locations, mergeAsset} = useAssetContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [locationId,setLocationId] = React.useState<string>(props.locationId || '')
  const [notes,setNotes] = React.useState<string>(props.notes || '')
  const [deviceStatus, setDeviceStatus] = React.useState<string>(props.deviceStatus || '')
  const styles = useStyles();
  const applyStyles = useApplyButtonStyles();
  
  const defaultLocationName = (Array.isArray(locations) && locations.length > 0) ? (
    locations.find((element: ILocationItem) => {
      return element.id === props.locationId;
    })?.locationName ?? ""
  ) : ("")
    
  // Apply
  const onClickApplyButton = React.useCallback(() => {
    setIsOpen(false);
    if ((notes !== props.notes) || 
        (deviceStatus !== props.deviceStatus) || 
        (locationId !== props.locationId)) {
      console.log({
        ...props,
        notes: notes,
        locationId: locationId,
        deviceStatus: deviceStatus,
      })
      mergeAsset({
        ...props,
        notes: notes,
        locationId: locationId,
        deviceStatus: deviceStatus,
      })
    }
  }, [props, notes, locationId, deviceStatus, mergeAsset]);

  // Notes
  const textLimit = 100
  const onChange: TextareaProps["onChange"] = (ev, data) => {
    if (data.value.length <= textLimit) {
      setNotes(data.value);
    }
  }

  // Device Status
  const onOptionSelectDeviceStatus: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setDeviceStatus(data.selectedOptions[0])
  };

  const DeviceStatusDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={deviceStatus}
        placeholder="Select a location"
        {...props}
        defaultValue={deviceStatus}
        defaultSelectedOptions={!deviceStatus ? ( [] ) : ( [deviceStatus])}
        onOptionSelect={onOptionSelectDeviceStatus}
      >
        {['Inactive','Available','Decommissioned'].map((status:string) => (
          <Option text={status} value={status}>
            {status}
          </Option>
        ))}
      </Dropdown>
    )
  },[deviceStatus])

  // Location
  const onOptionSelectLocation: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setLocationId(data.optionValue ?? "")
  };

  const LocationDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={"Location"}
        placeholder="Select a location"
        {...props}
      >
        {locations.map((location:ILocationItem) => (
          <Option key={location.locationName} value={location.id}>
            {location.locationName}
          </Option>
        ))}
      </Dropdown>
    );
  },[locations]);

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Device details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text font="monospace">
            <div role="tabpanel" aria-labelledby="item">
              <table className={styles.propsTable}>
                <tbody>
                  {
                    Object.entries(props).map(([key, value]) => 
                      ( value === true || value === false ) ? (
                          <tr><td>Boolean {key}</td><td>{value.toString()}</td></tr>
                        ):(
                          (key === 'locationId') ? (
                            <tr><td>Location</td><td>
                              <LocationDropdown 
                                defaultValue={defaultLocationName}
                                defaultSelectedOptions={[props.locationId]}
                                onOptionSelect={onOptionSelectLocation}
                              /><br />
                            </td></tr>
                          ) : (key === 'notes') ? (
                            <tr>
                              <td>Notes</td>
                              <td>
                                <Field label={"Notes is limited to " + textLimit + " characters"}>
                                  <Textarea resize="vertical" value={notes} onChange={onChange} />
                                </Field>
                              </td>
                            </tr>
                          ) : (key === 'deviceStatus' && (props.deviceStatus === 'Inactive' || props.deviceStatus === 'Available' || props.deviceStatus === 'Assigned')) ? (
                            <tr><td>Device status</td><DeviceStatusDropdown /></tr>
                          ) : (
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        )
                      )
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="outline" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};
*/

// Open Location Drawer
export const OpenLocation = (props: ILocationItem) => {
  const {putLocation, deleteLocation} = useAssetContext();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const refLocationName = React.useRef<string>(props.locationName || '');
  const applyStyles = useApplyButtonStyles();
  const styles = useStyles();

  const onClickApplyButton = React.useCallback(() => {
    setIsOpen(false);
    if (refLocationName.current !== props.locationName) {
      putLocation({
        ...props,
        locationName: refLocationName.current
      })
    }
  }, [props, putLocation]);

  const onClickDelete = React.useCallback(() => {
    if(window.confirm('Delete location? (' + props.locationName + ')' )) {
      // Delete item
      deleteLocation({
        id: props.id
      })      
    }
    // Close Drawer
    setIsOpen(false);
  }, [props, deleteLocation]);

  const LocationNameTextArea = () => {
    const [value, setValue] = React.useState<string>(props.locationName || '');
    const textLimit = 50
    const onChange: TextareaProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setValue(data.value);
        refLocationName.current = data.value;
      }
    };

    return (
      <>
      <Field label={"Location name is limited to " + textLimit + " characters"}>
        <Textarea value={value} onChange={onChange} />
      </Field>
      </>
    );
  };
  
  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <>
                <Button
                  appearance="subtle"
                  aria-label="Delete"
                  icon={<Delete24Regular />}
                  onClick={onClickDelete}
                />
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              </>
            }
          >
            Location Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
            <div role="tabpanel" aria-labelledby="item">
              <table className={styles.propsTable}>
                <tbody>
                  {
                    Object.entries(props).map(([key, value]) => (
                      (key === 'locationName') ? (
                        <tr><td>Location Name</td><td><LocationNameTextArea /></td></tr>
                      ) : ( 
                        <tr><td>{key}</td><td>{value}</td></tr>
                      )
                    ))
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickDelete}><Delete24Regular />Delete</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>

      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );

};

  // Open Location Drawer
export const AddLocation = () => {
  const {putLocation} = useAssetContext();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const refLocationName = React.useRef<string>('');
  
  const onClickAddButton = React.useCallback(() => {
    setIsOpen(false);
    putLocation({
      locationName: refLocationName.current
    })
  }, [putLocation]);

  const applyStyles = useApplyButtonStyles();

  const LocationNameTextArea = () => {
    const [value, setValue] = React.useState<string>('');
    const textLimit = 50
    const onChange: TextareaProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setValue(data.value);
        refLocationName.current = data.value;
      }
    };

    return (
      <>
      <Field label={"Location name is limited to " + textLimit + " characters"}>
        <Textarea value={value} onChange={onChange} />
      </Field>
      </>
    );
  };
  
  const useDrawerStyles = makeStyles({
    content: {
      display: "flex",
      justifyContent: "left",
      alignItems: "flex-start",
      columnGap: tokens.spacingHorizontalXS,
    },
  });

  const styles = useStyles();
  const drawerStyles = useDrawerStyles();
  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <>
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              </>
            }
          >
            Add Location
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              {
                // drawerContent
              }

              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                  {
                    <tr><td>Location Name</td><td><LocationNameTextArea /></td></tr>
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickAddButton}>Add</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <div className={drawerStyles.content}>
        <Button 
          shape="square"
          appearance="subtle"
          aria-label="New location"
          onClick={() => setIsOpen(true)} 
          icon={<LocationAdd24Regular />}
          size="medium"
        >New location</Button>
      </div>
    </div>
  );
};

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

const useDrawerTabsStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "hidden",
    overflowY: "auto",
  },
  input: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "300px",
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "700px",
  },
  button: {
    display: "flex",
    justifyContent: "left",
  },
});

export const OpenAssetItem = (props: IAssetItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {locations, mergeAsset} = useAssetContext();
  const applyStyles = useApplyButtonStyles();
  const cardStyles = useCardStyles();
  const refNotes = useRef<string>(props.notes || '')
  const refAssignedto = useRef<string>(props.assignedTo || '')
  const drawerTabsStyles = useDrawerTabsStyles();
  const [locationId,setLocationId] = React.useState<string>(props.locationId || '')
  const [deviceStatus, setDeviceStatus] = React.useState<string>(props.deviceStatus || '')

  // Device Status
  const onOptionSelectDeviceStatus: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setDeviceStatus(data.selectedOptions[0])
  };

  const DeviceStatusDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={deviceStatus}
        placeholder="Select a status"
        {...props}
        defaultValue={deviceStatus}
        defaultSelectedOptions={!deviceStatus ? ( [] ) : ( [deviceStatus])}
        onOptionSelect={onOptionSelectDeviceStatus}
      >
        {['Inactive','Available','Decommissioned'].map((status:string) => (
          <Option text={status} value={status}>
            {status}
          </Option>
        ))}
      </Dropdown>
    )
  },[deviceStatus])

  // Location
  const onOptionSelectLocation: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    console.log("location: " + data.optionValue)
    setLocationId(data.optionValue ?? "")
  };

  const LocationDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={"Location"}
        placeholder="Select a location"
        {...props}
        defaultValue={
          (Array.isArray(locations) && locations.length > 0) ? (
            locations.find((element: ILocationItem) => {
              return element.id === locationId;
            })?.locationName ?? ""
          ) : ("")
        }
        defaultSelectedOptions={[locationId]}
        onOptionSelect={onOptionSelectLocation}
      >
        {locations.map((location:ILocationItem) => (
          <Option key={location.locationName} value={location.id}>
            {location.locationName}
          </Option>
        ))}
      </Dropdown>
    );
  },[locations,locationId]);

  const DeviceServiceCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Device name</td><td>{props.deviceName}</td></tr>
                  <tr><td>Device type</td><td>{props.deviceType}</td></tr>
                  <tr><td>Operating system</td><td>{props.operatingSystem}</td></tr>
                  <tr><td>User Displayname</td><td>{props.userDisplayName}</td></tr>
                  <tr><td>Userprincipalname</td><td>{props.userPrincipalName}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const DeviceDetailsServiceCard =  React.useCallback(() => {
    
    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Manufacturer</td><td>{props.manufacturer}</td></tr>
                  <tr><td>Model</td><td>{props.model}</td></tr>
                  <tr><td>Serial number</td><td>{props.serialNumber}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const SecondServiceCard =  React.useCallback(() => {
    
    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>First enrolled</td><td>{props.firstEnrolledDateTime}</td></tr>
                  <tr><td>Enrolled</td><td>{props.enrolledDateTime}</td></tr>
                  <tr><td>Device registration state</td><td>{props.deviceRegistrationState}</td></tr>
                  <tr><td>Group tag</td><td>{props.groupTag}</td></tr>
                  <tr><td>Autopilot Enrolled</td><td>{( props.autopilotEnrolled === true || props.autopilotEnrolled === false ) ? (props.autopilotEnrolled.toString()) : (props.autopilotEnrolled)}</td></tr>
                  <tr><td>Deployment profile status</td><td>{props.deploymentProfileAssignmentStatus}</td></tr>
                  <tr><td>Intune Device ID</td><td>{props.managedDeviceId}</td></tr>
                  <tr><td>Microsoft Entra Device ID</td><td>{props.azureActiveDirectoryDeviceId}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const NotesPanel = React.memo(() => {
    const [notesValue, setNotesValue] = React.useState<string>(refNotes.current || '')
    const notesTextLimit = 200

    const onChangeNotes: TextareaProps["onChange"] = (ev, data) => {
      if (data.value.length <= notesTextLimit) {
        setNotesValue(data.value);
        refNotes.current = data.value;
      }
    };

    return (
      <div role="tabpanel" aria-labelledby="Notes">
        <Text>
          <div role="tabpanel" aria-labelledby="item">
            <div className={drawerTabsStyles.container}>
              <Card className={cardStyles.card}>
                <CardHeader
                  header={
                    <Body1>
                      <b>Notes</b>
                    </Body1>
                  }
                  description={
                    <Caption2>
                      {"(" + notesTextLimit + " characters)"}
                    </Caption2>
                  }
                />
                <Textarea appearance="outline" resize="vertical" value={notesValue} onChange={onChangeNotes} rows={8} />
              </Card>
            </div>
          </div>
        </Text>
      </div>
    )
  });

  const AssignedToInput = React.memo(() => {
    const [assignedToValue, setAssignedToValue] = React.useState<string>(refAssignedto.current || '')
    const assignedToTextLimit = 30

    const onChangeAssignedto: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= assignedToTextLimit) {
        setAssignedToValue(data.value);
        refAssignedto.current = data.value;
      }
    };

    return (
      <Input value={assignedToValue} onChange={onChangeAssignedto} className={drawerTabsStyles.input}/>
    )
  });

  const AssetManagementServiceCard =  React.useCallback(() => {
    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Device status</td>{((props.deviceStatus === 'Inactive' || props.deviceStatus === 'Available' || props.deviceStatus === 'Assigned')) ? (<DeviceStatusDropdown />):(<DeviceStatusDropdown disabled />)}</tr>
                  <tr><td>Location</td><td><LocationDropdown /></td></tr>
                  {
                    (props.deviceStatus === 'Available' || props.deviceStatus === 'Assigned') && (
                      <tr><td>Assigned to</td><td><AssignedToInput /></td></tr>
                    )
                  }
                  {
                    ((props.deviceStatus === 'Available' || props.deviceStatus === 'Assigned') && props?.assignedTo?.length > 0) && (
                      <>
                        <tr><td>Assigned by</td><td>{props.assignedBy}</td></tr>
                        <tr><td>Assignment date</td><td>{props.assignmentDate}</td></tr>                        
                      </>
                    )
                  }
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles,DeviceStatusDropdown,LocationDropdown,AssignedToInput]);

  const WarrantyServiceCard =  React.useCallback(() => {
    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Warranty status</td><td>{props.warrantyStatus}</td></tr>
                  <tr><td>Warranty start</td><td>{props.warrantyStart}</td></tr>
                  <tr><td>Warranty end</td><td>{props.warrantyEnd}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  // On-click Close Button Function
  const onClickCloseButton = React.useCallback(() => {
    setIsOpen(false);
      if ((refNotes.current !== props.notes) || 
          (refAssignedto.current !== props.assignedTo) || 
          (deviceStatus !== props.deviceStatus) || 
          (locationId !== props.locationId)) {
        console.log({
          ...props,
          notes: refNotes.current,
          assignedTo: refAssignedto.current,
          locationId: locationId,
          deviceStatus: deviceStatus,
        })
        mergeAsset({
          ...props,
          notes: refNotes.current,
          assignedTo: refAssignedto.current,
          locationId: locationId,
          deviceStatus: deviceStatus,
        })
      }
    }, [props, locationId, deviceStatus ,mergeAsset]);

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClickCloseButton} //{() => setIsOpen(false)}
              />
            }
          >
            Device details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <DeviceServiceCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <DeviceDetailsServiceCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <SecondServiceCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <WarrantyServiceCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <AssetManagementServiceCard />
            </div>
            <div role="tabpanel" aria-labelledby="item">
              <NotesPanel />
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};