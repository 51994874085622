import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
    Card,
    Body1,
  } from "@fluentui/react-components";
import * as React from "react";

import { Button, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { 
  Dismiss24Regular,
  BookInformation24Regular,
} from "@fluentui/react-icons";

import type { ILogItem } from "../../Context/AztaContext";

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

// Open Application Drawer
export const OpenItem = (props: ILogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  const styles = useStyles();
  const propertyFilter = [
  'odata.type',
  'odata.id',
  'odata.etag',
  'odata.editLink',
  'PartitionKey',
  'RowKey',
  'Timestamp@odata.type',
  'id@odata.type',
  ]

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Log details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text font="monospace">
              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                    {
                      Object.entries(props).map(([key, value]) => !propertyFilter.includes(key) ? (
                        ( value === true || value === false ) ? (
                              <tr><td>{key}</td><td>{value.toString()}</td></tr>
                          ):(
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        ) : (
                          <></>
                        )
                      )
                    }
                  </tbody>  
                </table>
              </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />}>
      </Button>
    </div>
  );
};

const useCardStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const OpenAztaItem = (props: ILogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  // ServiceCard
  const cardStyles = useCardStyles();

  const AztaTopCard =  React.useCallback(() => {

    return (
      <Card className={cardStyles.card}>
        <Body1>
            <div role="tabpanel" aria-labelledby="item">
            <table className={cardStyles.propsTable}>
                <tbody>
                  <tr><td>Assigned metadata</td><td>{props.assignedMetadata}</td></tr>
                  <tr><td>Device identifier</td><td>{props.deviceIdentifier}</td></tr>
                  <tr><td>Timestamp</td><td>{props.logDateTime}</td></tr>
                  <tr><td>New configuration</td><td>{props.newConfiguration}</td></tr>
                  <tr><td>Previous Configuration</td><td>{props.previousConfiguration}</td></tr>
                  <tr><td>Status</td><td>{props.status}</td></tr>
                </tbody>
              </table>
            </div>
        </Body1>
      </Card>
    );
  },[props,cardStyles]);

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <OverlayDrawer
        open={isOpen}
        position="end"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
        modalType="alert"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClickCloseButton} //{() => setIsOpen(false)}
              />
            }
          >
            Android Zero-touch assignment log details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text>
            <div role="tabpanel" aria-labelledby="item">
              <AztaTopCard />
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </OverlayDrawer>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />} />
    </div>
  );
};