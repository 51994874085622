import * as React from "react";
import { default as TabsWithPanels } from './TabsWithPanels'
import { ServiceProvider as Provider} from '../../Context/ServiceContext'
import { ServiceProvider as BillingProvider} from '../../Context/BillingContext'

export const WithPanels = () => {
  return (
    <Provider>
      <BillingProvider>
        <TabsWithPanels />
      </BillingProvider>
    </Provider>
  );
};

export default WithPanels;
