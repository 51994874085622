import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer, IServiceManagementItem } from "./ClientprincipalContext";

export interface IAssessmentItem {
  compliant: boolean;
  controlid: string;
  id: string;
  reportid: number;
  scoreachived: number;
  status: string;
  category: string;
  description: string;
  displayname: string;
  implementation: string;
  product: string;
  scoremax: number;
  notes: string;
  isaccepted: boolean;
  isaddressed: boolean;
  accepteduntil: string;
  assignedto: string;
}

export type AssessmentContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: IServiceManagementItem | undefined;
  assessmentItems: IAssessmentItem[];
  isLoadingAssessmentItems: boolean;
  refreshAssessmentItems: () => void;
  patchAssessmentItem: (props: Partial<IAssessmentItem>) => void;
};

const AssessmentDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  assessmentItems: [],
  isLoadingAssessmentItems: false,
  refreshAssessmentItems: () => {},
  patchAssessmentItem: (props: Partial<IAssessmentItem>) => {},
};

export interface IProviderProps {
children?: any;
}

const AssetContext = React.createContext<AssessmentContextType>(AssessmentDefaultValue)

export const useAssessmentContext = () => {
  const context = React.useContext(AssetContext);
  if (!context) {
    throw new Error(
      `useAssetContext must be used within a AssessmentProvider`
    )
  }
  return context;
}

export const AssessmentProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<IServiceManagementItem | undefined>(undefined);
  const [assessmentItems, setAssessmentItems] = React.useState<IAssessmentItem[]>([]);
  const [isLoadingAssessmentItems, setIsLoadingAssessmentItems] = React.useState<boolean>(false)
  const {clientprincipal, serviceOffers, serviceSubscriptions} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);

  const refreshAssessmentItems = React.useCallback(() => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('assessment') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
      setIsLoadingAssessmentItems(true);
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            'tenantId': clientprincipal.tenantId,
            'userprincipalname': clientprincipal.userName,
            'uid': clientprincipal.userId,
            'displayname': clientprincipal.displayName,
          },
      };
      fetch('/api/assessment/items', requestOptions)
      .then((response) => response.json())
      .then((result) => setAssessmentItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingAssessmentItems(false);
      })
    } else {
      setAssessmentItems([])
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName]);

  const patchAssessmentItem = React.useCallback((props: Partial<IAssessmentItem>) => {
    if( subscription?.isEnabled && clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('assessment') 
        && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
        && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string') {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json', 
          'tenantId': clientprincipal.tenantId,
          'userprincipalname': clientprincipal.userName,
          'uid': clientprincipal.userId,
          'displayname': clientprincipal.displayName,
        },
        body: JSON.stringify({
          id: props.id,
          isaccepted: props.isaccepted,
          isaddressed: props.isaddressed,
          assignedto: props.assignedto,
          notes: props.notes,
          accepteduntil: props.accepteduntil
        }),
      };
      fetch('/api/assessment/items', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshAssessmentItems();
      })
    }
  },[subscription?.isEnabled,clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, clientprincipal?.userName, clientprincipal?.userId, clientprincipal?.displayName, refreshAssessmentItems]);

  // Init asset array
  React.useEffect(() => {
    refreshAssessmentItems();
  },[clientprincipal, refreshAssessmentItems]);

  // Set serviceOffer
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "assessment";
      }))
  },[serviceOffers, setServiceOffer]);

  // Set subscription
  React.useEffect(() => {
    setSubscription(
      serviceSubscriptions.find((element: IServiceManagementItem) => {
        return element.name === "assessment";
      })
    )
  },[serviceSubscriptions, subscription, setSubscription]);

  const value = React.useMemo(() => ({serviceOffer, subscription, assessmentItems, isLoadingAssessmentItems, refreshAssessmentItems, patchAssessmentItem }), [serviceOffer, subscription, assessmentItems, isLoadingAssessmentItems, refreshAssessmentItems, patchAssessmentItem])
  return <AssetContext.Provider value={value} {...props} />
}


