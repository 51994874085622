import * as React from "react";
import {
  Button,
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
} from "@fluentui/react-components";
import { ShowWifiXml, ShowRadsecproxyXml } from './Drawer'
import { useRadiusContext } from '../../Context/RadiusContext'

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

export const Faq = () => {
  const styles = useStyles();
  const {caCertificateDownload, clientCertificateDownload} = useRadiusContext();

  return (
    <div className={styles.container}>
        <LargeTitle>Instructions</LargeTitle>
        <Body1 align="justify">Ths guide describes the configuration steps needed to start using the RADSEC service.</Body1>
        <Title3>1. Approve the Modern Managed RADSEC Service application.</Title3>
        <Body1 align="justify">Allow the Modern Managed RADSEC Service application to access your AzureAD tenant.</Body1>
        <Title3>2. Download certifiacates</Title3>
        <Body1 align="justify">Download the certificates used by the Modern Managed RADSEC service.</Body1>
        <Subtitle1>2.1 Download the RADSEC client certificate</Subtitle1>
        <Body1 align="start">
          <Button onClick={clientCertificateDownload} shape="circular">Download RADSEC client certificate</Button>
        </Body1>
        <Subtitle1>2.2 Download the RADSEC CA certificate</Subtitle1>
        <Body1 align="start">
          <Button onClick={caCertificateDownload} shape="circular">Download RADSEC Certificate Authority Certificate</Button>
        </Body1>
        <Title3>3. Configure RADSEC</Title3>
        <Subtitle1>3.1 Configure RADSEC on your AP's</Subtitle1>
        <Body1 align="justify">
          <li>Create a RADSEC authentication server on your AP.</li>
          <li>Set the server to 'radsec-service.modernmanaged.net'.</li>
          <li>Ensure that you AP can resolve the address by configuring at least one dns server.</li>
        </Body1>
        <Body1 italic>
          Note: If your infrastructure do not support RADSEC, a linux server running radsecproxy can be used to proxy RADIUS request to overcome this limitation.
        </Body1>
        <Subtitle1>3.2 Deploy radsecproxy</Subtitle1>
        <Body1 align="justify">
          This example configuration can be used when implementing radsecproxy for Modern Managed RADSEC Service.<br/>
          Set the server to 'radsec-service.modernmanaged.net'.<br/>
          <ShowRadsecproxyXml />
        </Body1>
        <Title3>4. Configure WiFi client profile</Title3>
        <Body1 align="justify">
          .
        </Body1>
        <Subtitle1>4.1 Download sample Wifi xml profile.</Subtitle1>
        <Body1 align="justify">
          Download the sample xml and update the SSID by replacing "YOUR_SSID_HERE"<br/>
          <br/> 
          <ShowWifiXml />
        </Body1>
        <Subtitle1>4.2 Deploy WiFi Profile.</Subtitle1>
        <Body1 align="justify">
          Deploy WiFi Profile with Intune, or manually through this command:<br/>
          "netsh wlan add profile 'PATH_TO_FILE'"<br/>
        </Body1>
        <Body1 align="justify">
          When the profile has been deployed you should be able to successfully 
          connect to new SSID using the normal Windows UIflow as long as the device 
          is AzureAD joined and in a compliant state.
        </Body1>
      </div>
    )
};

export default Faq;