import * as React from "react";
import {
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  //Subtitle1,
  Title3,
  Button,
} from "@fluentui/react-components";
import { Link } from "@fluentui/react-components";
import type { LinkProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const approvallink = "https://login.microsoftonline.com/common/adminconsent?client_id=56df1994-cd21-4071-b74a-1a9d4824aeaf"

const ApprovAccess = (props: LinkProps & { as?: "a" }) => (
  <Link href={approvallink} as="a" target="_blank">
    <Button shape="circular" appearance="outline">Approve Access</Button>
  </Link>
);

const CopyLink = () => (
  <Button shape="circular" appearance="outline" onClick={() => {navigator.clipboard.writeText(approvallink)}}>
    Copy Link
  </Button>
)

export const Faq = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
        <LargeTitle>Instructions</LargeTitle>
        <Body1 align="justify">Ths guide describes the configuration steps needed to start using the Asset Management service.</Body1>
        <Title3>1. Approve the Modern Managed Assessment Management Service application.</Title3>
        <Body1 align="justify">Allow the Assessmentt Management Service application access to your Microsoft EntraID tenant by approving the enterprise application.</Body1>
        <Body1 align="start">
          <ApprovAccess /> <CopyLink />
        </Body1>
        <Title3>2. Assement Items</Title3>
        <Body1 align="justify">Wait for the service to create the Assessment items.</Body1>
        <Title3>3. Work with assessment items</Title3>
        <Body1 align="justify">
          <li>Review your assessment items.</li>
          <li>Address your assessment items by defining to address status to evaluate, planed, in progress and closed.</li>
          <li>The notes field can be used to document evaluations, plans and track assessment item progress.</li>
        </Body1>
      </div>
    )
};

export default Faq;