import React from "react"
import {useClientprincipal} from '../Context/ClientprincipalContext'

// Auditlog item
export interface IAuditlogItem {
  id: string; // logid
  activitytimestamp: string;
  activitytype: string;
  category: string;
  status: string;
  statusreason: string;
  userprincipalname: string;
  displayname: string;
  uid: string;
  tid: string;
  targets: string;
}

export interface ISigninLogItem {
  tenantId: string;
  userprincipalName: string;
  userName: string;
  logTimestamp: string;
  roles: string
  amr: string;
  userId: string;
}

export interface ISubscriptionLogItem {
  tenantid: string;
  serviceid: string;
  userid: string;
  username: string;
  logTimestamp: string;
  action: string;
}

export type ServiceContextType = {
  subscriptionlogItems: ISubscriptionLogItem[];
  isLoadingServicelogs: boolean;
  signinlogItems: ISigninLogItem[];
  isLoadingSigninlogs: boolean;
  auditlogItems: IAuditlogItem[];
  isLoadingAuditlog: boolean;
  getSubscriptionlogItems: () => void;
  getSigninlogItems: () => void;
  getAuditlogItems: () => void;
};

const ServiceDefaultValue = {
  subscriptionlogItems: [],
  isLoadingServicelogs: false,
  signinlogItems: [],
  isLoadingSigninlogs: false,
  auditlogItems: [],
  isLoadingAuditlog: false,
  getSubscriptionlogItems: () => {},
  getSigninlogItems: () => {},
  getAuditlogItems: () => {},
};

export interface IProviderProps {
children?: any;
}

const ServiceContext = React.createContext<ServiceContextType>(ServiceDefaultValue)

export const useServiceContext = () => {
  const context = React.useContext(ServiceContext);
  if (!context) {
    throw new Error(
      `useServiceContext must be used within a ServiceProvider`
    )
  }
  return context;
}

export const ServiceProvider = (props: IProviderProps) => {
  const [signinlogItems, setSigninlogItems] = React.useState<ISigninLogItem[]>([]);
  const [subscriptionlogItems, setSubscriptionlogItems] = React.useState<ISubscriptionLogItem[]>([]);
  const [isLoadingSigninlogs, setIsLoadingSigninlogs] = React.useState<boolean>(false)
  const [isLoadingServicelogs, setIsLoadingServicelogs] = React.useState<boolean>(false)
  const [auditlogItems, setAuditlogItems] = React.useState<IAuditlogItem[]>([]);
  const [isLoadingAuditlog, setIsLoadingAuditlog] = React.useState<boolean>(false)
  const {clientprincipal} = useClientprincipal();
  
  const getSigninlogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      setIsLoadingSigninlogs(true);
      
      // Get services 
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
      };
      fetch('/api/services/sign-in/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => {setSigninlogItems(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingSigninlogs(false);
      })
    } else {
      //setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  const getSubscriptionlogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      setIsLoadingServicelogs(true);
      
      // Get service log items
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId,
                    'userprincipalname': clientprincipal.userName,
                    'uid': clientprincipal.userId,
                    'displayname': clientprincipal.displayName,
                  },
      };
      fetch('/api/services/subscription/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => {setSubscriptionlogItems(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingServicelogs(false);
      })
    } else {
      //setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);
  
  const getAuditlogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') 
      && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'
      && typeof clientprincipal?.userId === 'string' && typeof clientprincipal?.displayName === 'string'){
      // Get Logs
      setIsLoadingAuditlog(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId,
                      'userprincipalname': clientprincipal.userName,
                      'uid': clientprincipal.userId,
                      'displayname': clientprincipal.displayName,
                   },
      };
      fetch('/api/services/auditlog', requestOptions)
      .then((response) => response.json())
      .then((result) => setAuditlogItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingAuditlog(false);
      })
      
    } else {
      setAuditlogItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.userName, clientprincipal?.tenantId, clientprincipal?.userId, clientprincipal?.displayName]);

  // Init service items array
  React.useEffect(() => {
    getSigninlogItems();
  },[clientprincipal, getSigninlogItems]);

  // Init service items array
  React.useEffect(() => {
    getSubscriptionlogItems();
  },[clientprincipal, getSubscriptionlogItems]);

  // Get audit log items
  React.useEffect(() => {
    getAuditlogItems();
  },[clientprincipal, getAuditlogItems]);

  const value = React.useMemo(() => ({auditlogItems, isLoadingAuditlog, subscriptionlogItems, isLoadingServicelogs, signinlogItems, isLoadingSigninlogs, getAuditlogItems, getSubscriptionlogItems, getSigninlogItems}), [auditlogItems, isLoadingAuditlog, subscriptionlogItems, isLoadingServicelogs, signinlogItems, isLoadingSigninlogs, getAuditlogItems, getSubscriptionlogItems, getSigninlogItems])
  return <ServiceContext.Provider value={value} {...props} />
}
